import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";

const PREFIX = "BrowseFiles";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("label")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "center",
    fontSize: "14px",
    lineHeight: "19px",
    color: theme.palette.viduxColors.grey_6,
    fontWeight: 400,
    backgroundColor: theme.palette.viduxColors.grey_2,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    padding: "6px 8px 5px",
  },
}));

const BrowseFiles = ({ fileInputKey, onChangeAddFiles }) => {
  const { t } = useTranslation();
  return (
    <Root className={classes.root}>
      {t("contactUs.browseButtonText")}
      <input
        aria-label="addfiles"
        key={fileInputKey || ""}
        hidden
        type="file"
        multiple
        className="form-control"
        accept="image/*, video/*"
        name="files"
        id="files"
        onChange={onChangeAddFiles}
      />
    </Root>
  );
};

export default BrowseFiles;

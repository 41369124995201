import React from "react";
import { styled } from "@mui/material/styles";
import ReporterMenuItem from "./ReporterMenuItem";
import FaqMenuIcon from "../svgIcons/FaqMenuIcon";
import ContactUsMenuIcon from "../svgIcons/ContactUsMenuIcon";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../constants";
import { useTranslation } from "react-i18next";

const PREFIX = "ReporterMenuContainer";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  menuItem: `${PREFIX}-menuItem`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    gap: "15px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      gap: "30px",
    },
  },
}));

const ReporterMenuContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Root className={classes.root}>
      <ReporterMenuItem
        icon={FaqMenuIcon}
        title={t("home.faqMenuItemTitle")}
        description={t("home.faqMenuItemDescription")}
        onClick={() => navigate(ROUTE_PATHS.FAQ)}
      />
      <ReporterMenuItem
        icon={ContactUsMenuIcon}
        title={t("home.contactUsMenuItemTitle")}
        description={t("home.contactUsMenuItemDescription")}
        onClick={() => navigate(ROUTE_PATHS.CONTACT_US)}
      />
    </Root>
  );
};

export default ReporterMenuContainer;

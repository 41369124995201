import {
  GET_LOGGED_IN_USER,
  LOGGED_IN_USER_ALERT,
  CLEAR_LOGGED_IN_USER_ALERT,
} from "../types";

const loggedInUserReducer = (state, action) => {
  switch (action.type) {
    case GET_LOGGED_IN_USER:
      return {
        ...state,
        loggedInUser: action.payload,
      };
    case LOGGED_IN_USER_ALERT:
      return {
        ...state,
        loggedInUserAlert: action.payload,
      };
    case CLEAR_LOGGED_IN_USER_ALERT:
      return {
        ...state,
        loggedInUserAlert: null,
      };
    default:
      return state;
  }
};

export default loggedInUserReducer;

import React, { useState, useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Advice from "../common/Advice";
import { useTranslation } from "react-i18next";
import Attachments, { PREFIX as ATTACHMENTS_PREFIX } from "./Attachments";
import Description, { PREFIX as DESCRIPTION_PREFIX } from "./Description";
import PersonalInformation, {
  PREFIX as PERSONAL_INFORMATION_PREFIX,
} from "./PersonalInformation";
import SystemInformation, {
  PREFIX as SYSTEM_INFORMATION_PREFIX,
} from "./SystemInformation";
import FormButtons from "./FormButtons";
import LoggedInUserContext from "../context/loggedInUser/loggedInUserContext";
import {
  checkCountry,
  checkDescription,
  checkEmail,
  checkName,
  checkPhone,
  checkSerialNumber,
} from "../utils/validate";

const PREFIX = "Form";

const classes = {
  root: `${PREFIX}-root`,
  attachmentsTitle: `${ATTACHMENTS_PREFIX}-title`,
  descriptionTitle: `${DESCRIPTION_PREFIX}-title`,
  personalInformationTitle: `${PERSONAL_INFORMATION_PREFIX}-title`,
  systemInformationTitle: `${SYSTEM_INFORMATION_PREFIX}-title`,
  attachmentsSubTitle: `${ATTACHMENTS_PREFIX}-subTitle`,
  descriptionSubTitle: `${DESCRIPTION_PREFIX}-subTitle`,
  personalInformationError: `${PERSONAL_INFORMATION_PREFIX}-error`,
  systemInformationError: `${SYSTEM_INFORMATION_PREFIX}-error`,
  descriptionError: `${DESCRIPTION_PREFIX}-error`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "30px",
    flexGrow: 1,
  },
  [`& .${classes.attachmentsTitle},& .${classes.descriptionTitle},& .${classes.personalInformationTitle},& .${classes.systemInformationTitle}`]:
    {
      fontWeight: 800,
      fontSize: "20px",
      lineHeight: "27px",
      color: theme.palette.viduxColors.blue_4,
    },
  [`& .${classes.attachmentsSubTitle},& .${classes.descriptionSubTitle}`]: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    color: theme.palette.viduxColors.grey_6,
  },
  "& .MuiFormControl-root": {
    //selected item
    "& .MuiInputBase-root,& .MuiSelect-select": {
      fontWeight: 800,
      lineHeight: "19px",
      fontSize: "14px",
      color: theme.palette.viduxColors.grey_6,
    },
    //label-not-shrinked
    "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
      color: theme.palette.viduxColors.grey_6,
      fontWeight: 400,
      lineHeight: "19px",
      fontSize: "14px",
    },
    //label-shrinked
    "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      fontWeight: 400,
      lineHeight: "19px",
      fontSize: "14px",
      transform: "translate(14px, -9px) scale(1)",
      backgroundColor: "white",
      paddingRight: "4px",
      //not in focus
      "&:not(.Mui-focused)": {
        color: theme.palette.viduxColors.grey_6,
      },
    },
  },
  [`& .${classes.personalInformationError},& .${classes.systemInformationError},& .${classes.descriptionError}`]:
    {
      borderColor: "red",
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      "& .MuiOutlinedInput-input": {
        color: "red",
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "red",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "red",
      },
      "& .MuiInputLabel-outlined": {
        color: "red",
      },
      "&:hover .MuiInputLabel-outlined": {
        color: "red",
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "red",
      },
    },
}));

const Form = () => {
  const { t } = useTranslation();
  const loggedInUserContext = useContext(LoggedInUserContext);
  const { loggedInUser } = loggedInUserContext;

  const [formData, setFormData] = useState({
    attachments: [],
    description: "",
    serialNumber: "",
    country: "",
    name: "",
    emailAddress: "",
    phone: "",
  });

  const [isValidSerialNumber, setIsValidSerialNumber] = useState(
    checkSerialNumber(formData.serialNumber),
  );
  const [isValidCountry, setIsValidCountry] = useState(
    checkCountry(formData.country),
  );
  const [isValidName, setIsValidName] = useState(checkName(formData.name));
  const [isValidEmail, setIsValidEmail] = useState(
    checkEmail(formData.emailAddress),
  );
  const [isValidPhone, setIsValidPhone] = useState(checkPhone(formData.phone));
  const [isValidDescription, setIsValidDescription] = useState(
    checkDescription(formData.description),
  );

  const [isValidForm, setIsValidForm] = useState(
    isValidSerialNumber &&
      isValidCountry &&
      isValidName &&
      isValidEmail &&
      isValidPhone &&
      isValidDescription,
  );

  useEffect(() => {
    setFormData((data) => ({
      ...data,
      country: loggedInUser.countryCode,
      name: loggedInUser.name,
      emailAddress: loggedInUser.email,
      phone: loggedInUser.phone,
    }));
  }, [loggedInUser]);

  useEffect(() => {
    const checkSerialNumberResult = checkSerialNumber(formData.serialNumber);
    setIsValidSerialNumber(checkSerialNumberResult);
    const checkCountryResult = checkCountry(formData.country);
    setIsValidCountry(checkCountryResult);
    const checkNameResult = checkName(formData.name);
    setIsValidName(checkNameResult);
    const checkEmailResult = checkEmail(formData.emailAddress);
    setIsValidEmail(checkEmailResult);
    const checkPhoneResult = checkPhone(formData.phone);
    setIsValidPhone(checkPhoneResult);
    const checkDescriptionResult = checkDescription(formData.description);
    setIsValidDescription(checkDescriptionResult);

    setIsValidForm(
      checkSerialNumberResult &&
        checkCountryResult &&
        checkNameResult &&
        checkEmailResult &&
        checkPhoneResult &&
        checkDescriptionResult,
    );
  }, [formData]);

  return (
    <Root className={classes.root}>
      {!isValidForm && <Advice text={t("contactUs.advice")} />}
      <Grid container spacing={"30px"}>
        <Grid item xs={12} sm={6}>
          <SystemInformation
            formData={formData}
            setFormData={setFormData}
            validation={{ isValidCountry, isValidSerialNumber }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PersonalInformation
            formData={formData}
            setFormData={setFormData}
            validation={{ isValidName, isValidEmail, isValidPhone }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Description
            setFormData={setFormData}
            validation={{ isValidDescription }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Attachments
            attachments={formData.attachments}
            setFormData={setFormData}
          />
        </Grid>
      </Grid>
      <FormButtons isValidForm={isValidForm} formData={formData} />
    </Root>
  );
};

export default Form;

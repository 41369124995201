import React from "react";
import { styled } from "@mui/material/styles";
import AdminContent from "../home/AdminContent";
import ReporterContent from "../home/ReporterContent";
import { MAX_PAGE_WIDTH, ROLES } from "../constants";
import AuthorizedElement from "../common/auth/AuthorizedElement";

const PREFIX = "Home";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ _theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: `${MAX_PAGE_WIDTH}px`,
    height: "max-content",
    gap: "60px",
    padding: "30px 15px 20px 15px",
  },
}));

const Home = () => {
  return (
    <Root className={classes.root}>
      <AuthorizedElement roles={[ROLES.REPORTER, ROLES.ADMIN]}>
        <ReporterContent />
      </AuthorizedElement>
      <AuthorizedElement roles={[ROLES.ADMIN]}>
        <AdminContent />
      </AuthorizedElement>
    </Root>
  );
};

export default Home;

import { React, useCallback, useContext } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { ButtonBase, Typography } from "@mui/material";
import { keycloak } from "../utils/keycloakService";
import LoggedInUserContext from "../context/loggedInUser/loggedInUserContext";
import { COUNTRY_OPTIONS } from "../constants";

const PREFIX = "MyProfile";
const HEADER_H = "50px";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  card: `${PREFIX}-card`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  row: `${PREFIX}-row`,
  key: `${PREFIX}-key`,
  val: `${PREFIX}-val`,
  btnContainer: `${PREFIX}-btnContainer`,
  btn: `${PREFIX}-btn`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 15px",
  },
  [`& .${classes.card}`]: {
    width: "100%",
    maxWidth: "max-content",
    position: "relative",
    textAlign: "center",
    backgroundColor: "white",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "22px",
  },
  [`& .${classes.header}`]: {
    position: "relative",
    width: "100%",
    height: HEADER_H,
    backgroundColor: theme.palette.viduxColors.green_3,
    textAlign: "left",
    borderRadius: "4px 4px 0px 0px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "30px",
  },
  [`& .${classes.title}`]: {
    position: "relative",
    color: "white",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "27px",
  },
  [`& .${classes.content}`]: {
    position: "relative",
    width: "100%",
    height: "min-content",
    padding: "30px",
    color: theme.palette.viduxColors.grey_5,
    "& table": {
      paddingBottom: "20px",
      borderSpacing: "0px",
    },
  },
  [`& .${classes.row} th`]: {
    paddingRight: "5px",
  },
  [`& .${classes.row} th,& .${classes.row} td`]: {
    paddingBottom: "10px",
  },
  [`& .${classes.key}`]: {
    fontWeight: "bold",
    textAlign: "left",
    verticalAlign: "top",
  },
  [`& .${classes.val}`]: {
    textAlign: "left",
    fontWeight: "normal",
    wordBreak: "break-word",
  },
  [`& .${classes.btnContainer}`]: {
    position: "relative",
    textAlign: "left",
  },
  [`& .${classes.btn}`]: {
    height: "36px",
    padding: "7px 15px",
    backgroundColor: theme.palette.viduxColors.green_3,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    color: "white",
    gap: "10px",
    "& svg": {
      fill: "currentColor",
    },
    "& .MuiTypography-root": {
      textTransform: "none",
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
}));

const MyProfile = () => {
  const { t } = useTranslation();
  const loggedInUserContext = useContext(LoggedInUserContext);
  const { loggedInUser } = loggedInUserContext;

  const concatColon = useCallback((string) => string + ":", []);

  return (
    <Root className={classes.root}>
      <div className={classes.card}>
        <div className={classes.header}>
          <span className={classes.title}>{t("common.myProfile")}</span>
        </div>
        <div className={classes.content}>
          <table>
            <tbody>
              <tr className={classes.row}>
                <th className={classes.key}>
                  {concatColon(t("common.username"))}
                </th>
                <td className={classes.val}>{loggedInUser.username}</td>
              </tr>
              <tr className={classes.row}>
                <th className={classes.key}>{concatColon(t("common.name"))}</th>
                <td className={classes.val}>{loggedInUser.name}</td>
              </tr>
              <tr className={classes.row}>
                <th className={classes.key}>
                  {concatColon(t("common.email"))}
                </th>
                <td className={classes.val}>{loggedInUser.email}</td>
              </tr>
              <tr className={classes.row}>
                <th className={classes.key}>
                  {concatColon(t("common.phoneNr"))}
                </th>
                <td className={classes.val}>{loggedInUser.phone}</td>
              </tr>
              <tr className={classes.row}>
                <th className={classes.key}>
                  {concatColon(t("common.country"))}
                </th>
                <td className={classes.val}>
                  {
                    COUNTRY_OPTIONS.find(
                      (countryObj) =>
                        countryObj.VALUE === loggedInUser?.countryCode,
                    )?.LABEL
                  }
                </td>
              </tr>
            </tbody>
          </table>
          <div className={classes.btnContainer}>
            <ButtonBase
              className={classes.btn}
              onClick={() => keycloak.login({ action: "UPDATE_PASSWORD" })}
            >
              <Typography>{t("common.changePassword")}</Typography>
            </ButtonBase>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default MyProfile;

import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import AttachmentsListItem from "./AttachmentsListItem";

const PREFIX = "AttachmentsList";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ _theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    rowGap: "10px",
  },
}));

const AttachmentsList = ({ attachments, onClickDeleteItem }) => {
  return (
    <Root className={classes.root}>
      {attachments.map((attachment, index) => (
        <AttachmentsListItem
          key={attachment.lastModified}
          attachment={attachment}
          onClickDeleteItem={onClickDeleteItem}
          index={index}
        />
      ))}
    </Root>
  );
};

export default memo(AttachmentsList);

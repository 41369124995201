import React from "react";

const FaqListDeleteIcon = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1989_15991)">
      <path
        d="M14.8 1.38889H11.6L10.6857 0.5H6.11429L5.2 1.38889H2V3.16667H14.8V1.38889ZM2.91429 14.7222C2.91429 15.7 3.73714 16.5 4.74286 16.5H12.0571C13.0629 16.5 13.8857 15.7 13.8857 14.7222V4.05556H2.91429V14.7222Z"
        fill="#565656"
      />
    </g>
    <defs>
      <clipPath id="clip0_1989_15991">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default FaqListDeleteIcon;

import React from "react";
import { styled } from "@mui/material/styles";
import Spinner from "react-spinkit";

const PREFIX = "EventLogSpinner";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 800,
    textAlign: "center",
    padding: "10px 0px",
    "& .sk-spinner.ball-beat": {
      color: theme.palette.viduxColors.grey_5,
      transform: "scale(.7)",
      animationDuration: "1s",
    },
  },
}));

const EventLogSpinner = () => {
  return (
    <Root className={classes.root}>
      <Spinner name="ball-beat" />
    </Root>
  );
};

export default EventLogSpinner;

import React from "react";
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import AdminCollapsingRowContent from "../AdminCollapsingRowContent";

const PREFIX = "AdminList";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ _theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
  },
}));

const AdminListCollapsingRow = ({ open, row }) => {
  return (
    <Root className={classes.root}>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <AdminCollapsingRowContent row={row} />
      </Collapse>
    </Root>
  );
};

export default AdminListCollapsingRow;

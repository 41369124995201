import React, { useEffect, useContext } from "react";
import LoggedInUserContext from "../context/loggedInUser/loggedInUserContext";
import { useSnackbar } from "notistack";

const AppInitializer = () => {
  const loggedInUserContext = useContext(LoggedInUserContext);
  const { getLoggedInUser, loggedInUserAlert, clearLoggedInUserAlert } =
    loggedInUserContext;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const abortController = new AbortController();
    getLoggedInUser(abortController);
    return () => abortController.abort();
  }, [getLoggedInUser]);

  useEffect(() => {
    if (loggedInUserAlert) {
      enqueueSnackbar(loggedInUserAlert.msg, {
        variant: loggedInUserAlert.severity,
      });
      clearLoggedInUserAlert();
    }
  }, [clearLoggedInUserAlert, enqueueSnackbar, loggedInUserAlert]);

  return <></>;
};

export default AppInitializer;

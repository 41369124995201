import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { AppBar } from "@mui/material";
import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "../constants";
import logo from "../assets/logo-vidux-vert.svg";
import MyAccountMenu from "./MyAccountMenu";
import SendingSpinnerContext from "../context/sendingSpinnerContext/sendingSpinnerContext";
import SendingSpinner from "./SendingSpinner";

const PREFIX = "Navbar";
export const NAV_BAR_H = 60;

const classes = {
  root: `${PREFIX}-root`,
  menu: `${PREFIX}-menu`,
  logo: `${PREFIX}-logo`,
  toolbar: `${PREFIX}-toolbar`,
};

const Root = styled(AppBar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: "fixed",
    width: "100%",
    height: `${NAV_BAR_H}px`,
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
    zIndex: theme.zIndex.drawer + 1,
  },
  [`& .${classes.toolbar}`]: {
    padding: "0px 15px",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      padding: "0px 30px",
    },
  },
  [`& .${classes.logo}`]: {
    width: "92px",
    height: "33px",
    backgroundImage: `url(${logo})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
}));

const Navbar = () => {
  const sendingSpinnerState = useContext(SendingSpinnerContext);
  const { percent } = sendingSpinnerState;
  return (
    <Root position="fixed" className={classes.root}>
      <div className={classes.toolbar}>
        <Link to={ROUTE_PATHS.HOME}>
          <div className={classes.logo} />
        </Link>
        <MyAccountMenu />
        <SendingSpinner sendingPercent={percent} />
      </div>
    </Root>
  );
};

export default Navbar;

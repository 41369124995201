import React from "react";

const FileIcon = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="30"
    viewBox="0 0 24 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 10.5V2.25L21.75 10.5H13.5ZM3 0C1.35 0 0 1.35 0 3V27C0 28.65 1.35 30 3 30H21C22.65 30 24 28.65 24 27V9L15 0H3Z"
      fill="#565656"
    />
  </svg>
);

export default FileIcon;

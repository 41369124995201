import React from "react";
import { styled } from "@mui/material/styles";
import AdminSystemInformation from "./AdminSystemInformation";
import AdminPersonalInformation from "./AdminPersonalInformation";
import AdminProcessEvents from "./AdminProcessEvents";

const PREFIX = "AdminCollapsingRowContent";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: "15px",
    padding: "15px 10px",
    [theme.breakpoints.up("sm")]: {
      gap: "30px",
      padding: "30px 20px",
    },
  },
}));

const AdminCollapsingRowContent = ({ row }) => {
  return (
    <Root className={classes.root}>
      <AdminSystemInformation row={row} />
      <AdminPersonalInformation row={row} />
      <AdminProcessEvents row={row} />
    </Root>
  );
};

export default AdminCollapsingRowContent;

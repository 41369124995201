import React from "react";
import { styled } from "@mui/material/styles";
import { Edit } from "@mui/icons-material";

const PREFIX = "FaqListAdminListItemNormalMode";

const classes = {
  root: `${PREFIX}-root`,
  iconEdit: `${PREFIX}-iconEdit`,
  listItemContainer: `${PREFIX}-listItemContainer`,
  textContainer: `${PREFIX}-textContainer`,
  question: `${PREFIX}-question`,
  answer: `${PREFIX}-answer`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    gap: "20px",
  },
  [`& .${classes.iconEdit}`]: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  [`& .${classes.textContainer}`]: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    flexDirection: "column",
    gap: "10px",
    "& .MuiOutlinedInput-root": {
      fontWeight: 800,
      fontSize: "14px",
      lineHeight: "19px",
    },
  },
  [`& .${classes.question}`]: {
    color: theme.palette.viduxColors.grey_6,
    fontWeight: 800,
    fontSize: "14px",
    lineHeight: "19px",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.up("sm")]: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
  [`& .${classes.answer}`]: {
    color: theme.palette.viduxColors.grey_6,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.up("sm")]: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
}));

const FaqListAdminListItemNormalMode = ({ question, answer, onClickEdit }) => {
  return (
    <Root className={classes.root}>
      <div className={classes.textContainer}>
        <div className={classes.question}>{question}</div>
        <div className={classes.answer}>{answer}</div>
      </div>
      <div className={classes.iconEdit} onClick={onClickEdit}>
        <Edit />
      </div>
    </Root>
  );
};

export default FaqListAdminListItemNormalMode;

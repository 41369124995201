import React, { useState, useMemo, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { ExpandLess } from "@mui/icons-material";
import { keyframes } from "@mui/styled-engine";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  ButtonBase,
} from "@mui/material";
import Advice from "../common/Advice";
import {
  ROUTE_PATHS,
  SINGLE_LEFT_POINTING_ANGLE_QUOTATION_MARK_CHAR_CODE,
} from "../constants/index";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import FaqListCheckIcon from "../svgIcons/FaqListCheckIcon";

/******************** */

const PREFIX = "FaqListUser";

const rotateDownAnim = keyframes`
    0% {
      transform: scale(1.3) rotate(0deg);
    }
    100% {
      transform: scale(1) rotate(180deg);
    }
`;

const rotateUpAnim = keyframes`
    0% {
      transform: scale(1.3) rotate(180deg);
    }
    100% {
      transform: scale(1) rotate(0deg);
    }
`;

const classes = {
  root: `${PREFIX}-root`,
  list: `${PREFIX}-list`,
  listItemContainer: `${PREFIX}-listItemContainer`,
  question: `${PREFIX}-question`,
  rotateUp: `${PREFIX}-rotateUp`,
  rotateDown: `${PREFIX}-rotateDown`,
  answer: `${PREFIX}-answer`,
  answerContainer: `${PREFIX}-answerContainer`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  backBtn: `${PREFIX}-backBtn`,
  reportBtn: `${PREFIX}-reportBtn`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    gap: "30px",
    flexDirection: "column",
  },
  [`& .${classes.list}`]: {
    display: "flex",
    rowGap: "15px",
    flexDirection: "column",
  },
  [`& .${classes.listItemContainer}`]: {
    display: "flex",
    flexDirection: "column",
    borderBottom: `1px solid ${theme.palette.viduxColors.grey_3}`,
    cursor: "pointer",
    "& .MuiListItemText-root": {
      margin: 0,
    },
  },
  [`& .${classes.question}`]: {
    padding: "10px",
    color: theme.palette.viduxColors.grey_6,
    whiteSpace: "pre-wrap",
    "& .MuiTypography-root": {
      fontWeight: 800,
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
  [`& .${classes.answerContainer}`]: {
    padding: "0px 10px 10px 10px",
  },
  [`& .${classes.answer}`]: {
    padding: "0px 18px",
    whiteSpace: "pre-wrap",
    borderLeft: `3px solid ${theme.palette.viduxColors.green_3}`,
  },

  [`& .${classes.rotateDown}`]: {
    animation: `${rotateDownAnim} .4s ${theme.transitions.easing.easeOut} forwards`,
  },

  [`& .${classes.rotateUp}`]: {
    animation: `${rotateUpAnim} .4s ${theme.transitions.easing.easeOut} forwards`,
  },

  [`& .${classes.content}`]: {
    width: "100%",
    height: "max-content",
    padding: "30px",
    backgroundColor: "white",
  },
  [`& .${classes.buttonContainer}`]: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column-reverse",
    padding: 0,
    backgroundColor: "inherit",
    alignItems: "center",
    gap: "15px",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.viduxColors.grey_0,
      flexDirection: "row",
      padding: "10px 10px 10px 30px",
    },
  },
  [`& .${classes.backBtn}`]: {
    textDecorationLine: "underline",
    color: theme.palette.viduxColors.blue_3,
    height: "fit-content",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
  },
  [`& .${classes.reportBtn}`]: {
    color: "white",
    backgroundColor: theme.palette.viduxColors.green_3,
    padding: "14px 22px",
    gap: "10px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
    "& svg": {
      minWidth: "20px",
      minHeight: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "18px 30px 17px",
      fontSize: "18px",
      lineHeight: "25px",
    },
  },
}));

const FaqListUser = ({ faqs }) => {
  const [openedItemNr, setOpenedItemNr] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const buttonText = useMemo(
    () =>
      [
        String.fromCharCode(
          SINGLE_LEFT_POINTING_ANGLE_QUOTATION_MARK_CHAR_CODE,
        ),
        t("faq.listBackButtonText"),
      ].join(" "),
    [t],
  );

  const onClickToggleListItem = useCallback((idx) => {
    setOpenedItemNr((prev) => (prev === idx ? null : idx));
  }, []);

  const isItemOpen = useCallback(
    (index) => index === openedItemNr,
    [openedItemNr],
  );

  return (
    <Root className={classes.root}>
      <Advice text={t("faq.advice")} />
      <List
        disablePadding
        component="nav"
        aria-labelledby="faq-list"
        className={classes.list}
      >
        {faqs.map(({ question, answer }, idx) => (
          <div
            key={question}
            className={classes.listItemContainer}
            onClick={() => onClickToggleListItem(idx)}
          >
            <ListItem
              disableGutters
              disablePadding
              className={classes.question}
            >
              <ListItemText primary={question} />
              <ExpandLess
                className={
                  isItemOpen(idx) ? classes.rotateUp : classes.rotateDown
                }
              />
            </ListItem>
            <Collapse
              className={classes.answerContainer}
              in={isItemOpen(idx)}
              timeout="auto"
              unmountOnExit
            >
              <List className={classes.answer} component="div" disablePadding>
                <ListItem disableGutters disablePadding>
                  <ListItemText primary={answer} />
                </ListItem>
              </List>
            </Collapse>
          </div>
        ))}
      </List>
      <div className={classes.buttonContainer}>
        <ButtonBase className={classes.backBtn} onClick={() => navigate(-1)}>
          {buttonText}
        </ButtonBase>
        <ButtonBase
          className={classes.reportBtn}
          onClick={() => navigate(ROUTE_PATHS.CONTACT_US)}
        >
          <FaqListCheckIcon />
          {t("faq.listReportButtonText")}
        </ButtonBase>
      </div>
    </Root>
  );
};

FaqListUser.propTypes = {
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default FaqListUser;

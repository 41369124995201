import React from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FormattedTimeStamp from "../../common/FormattedTimeStamp";
import { getBackgroundColor, getStatusText } from "../../utils/adminEvents";

const PREFIX = "AdminTableMainRow";

const classes = {
  root: `${PREFIX}-root`,
  odd: `${PREFIX}-odd`,
  normal: `${PREFIX}-normal`,
  noData: `${PREFIX}-noData`,
  failed: `${PREFIX}-failed`,
  status: `${PREFIX}-status`,
};

const Root = styled(TableRow)(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: "white",
    "& .MuiTableCell-root": {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "19px",
      color: theme.palette.viduxColors.grey_6,
      "& .MuiButtonBase-root": {
        padding: 0,
        width: "min-content",
        height: "100%",
      },
      [`&.MuiTableCell-body.${classes.status}`]: {
        fontWeight: 800,
        color: theme.palette.viduxColors.green_3,
      },
    },
  },
  [`&.${classes.normal}`]: {
    backgroundColor: theme.palette.viduxColors.grey_0,
  },
  [`&.${classes.noData}`]: {
    backgroundColor: theme.palette.viduxColors.red_0,
    "& .MuiTableCell-root": {
      color: theme.palette.viduxColors.red_2,
      [`&.MuiTableCell-body.${classes.status}`]: {
        color: theme.palette.viduxColors.red_2,
      },
    },
  },
  [`&.${classes.failed}`]: {
    backgroundColor: theme.palette.viduxColors.orange_0,
    "& .MuiTableCell-root": {
      color: theme.palette.viduxColors.orange_1,
      [`&.MuiTableCell-body.${classes.status}`]: {
        color: theme.palette.viduxColors.orange_1,
      },
    },
  },
  [`&.${classes.odd}`]: {
    backgroundColor: theme.palette.viduxColors.grey_0,
  },
}));

const AdminTableMainRow = ({ row, open, setOpen, isOdd }) => {
  return (
    <Root
      className={`${classes.root} ${getBackgroundColor(
        PREFIX,
        isOdd,
        row.events,
      )}`}
    >
      <TableCell component="th" scope="row">
        {row.ticket.id}
      </TableCell>
      <TableCell align="left">
        <FormattedTimeStamp timeStamp={row.ticket.createdAt} />
      </TableCell>
      <TableCell align="left">{row.ticket.name}</TableCell>
      <TableCell align="left" className={classes.status}>
        {getStatusText(row.events)}
      </TableCell>
      <TableCell align="center">
        <IconButton
          disableRipple
          aria-label="expand row"
          size="small"
          onClick={() => setOpen((prev) => !prev)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
    </Root>
  );
};

export default AdminTableMainRow;

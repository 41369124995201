import React from "react";

const FaqListWarningIcon = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9091 10.7368H9.09091V6.26316H10.9091V10.7368ZM10.9091 14.3158H9.09091V12.5263H10.9091V14.3158ZM0 17H20L10 0L0 17Z"
      fill="#AE3838"
    />
  </svg>
);

export default FaqListWarningIcon;

import React from "react";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/styled-engine";
import { MAX_PAGE_WIDTH } from "../constants";
import AirplaneIcon from "../svgIcons/AirplaneIcon";
import { ButtonBase } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../constants/index";

const PREFIX = "Success";

const rotate = keyframes`
    0% {
      opacity: 0;
      transform: translateX(-120px) translateY(20px) rotateZ(20deg);
    }
    20% {
      opacity: 0;
      transform: translateX(-120px) translateY(20px) rotateZ(20deg);
    }
    100% {
      opacity: 1;
      transform: translateX(0px)  translateY(0px) rotateZ(0deg);
    }
`;

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  icon: `${PREFIX}-icon`,
  text: `${PREFIX}-text`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: `${MAX_PAGE_WIDTH}px`,
    height: "max-content",
    gap: "60px",
    padding: "30px 15px",
    [theme.breakpoints.up("sm")]: {
      padding: "120px 30px",
    },
  },
  [`& .${classes.content}`]: {
    width: "100%",
    padding: "60px 15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "30px",
    backgroundColor: "white",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    [theme.breakpoints.up("sm")]: {
      padding: "120px 30px",
    },
  },
  [`& .${classes.icon}`]: {
    transformOrigin: "top left",
    animation: `${rotate} 2.2s ${theme.transitions.easing.easeOut} forwards`,
  },
  [`& .${classes.text}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    textAlign: "center",
    "& div:nth-of-type(1)": {
      color: theme.palette.viduxColors.green_3,
      fontWeight: 800,
      fontSize: "18px",
      lineHeight: "25px",
      [theme.breakpoints.up("sm")]: {
        fontWeight: 800,
        fontSize: "20px",
        lineHeight: "27px",
      },
    },
    "& div:nth-of-type(2)": {
      color: theme.palette.viduxColors.grey_6,
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      [theme.breakpoints.up("sm")]: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "19px",
      },
    },
  },
  [`& .${classes.button}`]: {
    padding: "7px 15px",
    gap: "10px",
    border: `1px solid ${theme.palette.viduxColors.grey_3}`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
    color: theme.palette.viduxColors.grey_6,
  },
}));

const Success = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Root className={classes.root}>
      <div className={classes.content}>
        <AirplaneIcon className={classes.icon} />
        <div className={classes.text}>
          <div>{t("success.mainText")}</div>
          <div>{t("success.secondaryText")}</div>
        </div>
        <ButtonBase
          className={classes.button}
          onClick={() => navigate(ROUTE_PATHS.CONTACT_US)}
        >
          {t("success.buttonText")}
        </ButtonBase>
      </div>
    </Root>
  );
};

export default Success;

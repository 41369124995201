import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { PropTypes } from "prop-types";

const PREFIX = "AdminStyledTableCell";

const classes = {
  root: `${PREFIX}-root`,
  firstCell: `${PREFIX}-firstCell`,
  lastCell: `${PREFIX}-lastCell`,
};

const Root = styled(TableCell)(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.viduxColors.blue_2,
    borderBottom: "none",
    lineHeight: "unset",
    width: "auto",
  },
  [`&.${classes.firstCell}`]: {
    borderRadius: "4px 0px 0px 0px",
  },
  [`&.${classes.lastCell}`]: {
    borderRadius: "0px 4px 0px 0px",
    textAlign: "center",
    width: "1%",
    "& .MuiButtonBase-root": {
      width: "min-content",
      justifyContent: "center",
    },
  },
  "& .MuiSvgIcon-root": {
    fill: theme.palette.viduxColors.blue_4,
  },
  "& .MuiButtonBase-root.MuiTableSortLabel-root": {
    color: theme.palette.viduxColors.blue_4,
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "14px",
    lineHeight: "19px",
    height: "19px",
  },
}));

const AdminStyledTableCell = ({ isFirstCell, isLastCell, children }) => {
  return (
    <Root
      className={clsx(classes.root, {
        [classes.firstCell]: isFirstCell,
        [classes.lastCell]: isLastCell,
      })}
    >
      {children}
    </Root>
  );
};

AdminStyledTableCell.propTypes = {
  isFirstCell: PropTypes.bool.isRequired,
  isLastCell: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AdminStyledTableCell;

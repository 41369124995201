import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "FaqWindow";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: "max-content",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    filter: "drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.1))",
  },
  [`& .${classes.title}`]: {
    padding: "12px 15px 11px",
    color: "white",
    borderRadius: "4px 4px 0px 0px",
    backgroundColor: theme.palette.viduxColors.green_3,
    width: "100%",
    fontSize: "18px",
    fontWeight: 800,
    lineHeight: "25px",
    [theme.breakpoints.up("sm")]: {
      lineHeight: "27px",
      fontSize: "20px",
      padding: "12px 30px 11px",
    },
  },
  [`& .${classes.content}`]: {
    width: "100%",
    height: "max-content",
    padding: "30px 15px",
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      padding: "30px",
    },
  },
}));

const FaqWindow = ({ title, children }) => {
  return (
    <Root className={classes.root}>
      <div className={classes.title}>{title}</div>
      <div className={classes.content}>{children}</div>
    </Root>
  );
};

export default FaqWindow;

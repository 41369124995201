import React from "react";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/styled-engine";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

const PREFIX = "RotatedArrows";

const fallingFromTop = keyframes`
    0% {
      opacity: 1;
      transform: translateY(20%) rotateZ(270deg);
    }
    100% {
      opacity: 0;
      transform: translateY(-15%) rotateZ(270deg);
    }
`;

const fallingFromBottom = keyframes`
    0% {
      opacity: 1;
      transform: translateY(-20%) rotateZ(90deg);
    }
    100% {
      opacity: 0;
      transform: translateY(15%) rotateZ(90deg);
    }
`;

const fallingFromRight = keyframes`
    0% {
      opacity: 1;
      transform: translateX(0) rotateZ(0deg);
    }
    100% {
      opacity: 0;
      transform: translateX(30%) rotateZ(0deg);
    }
`;

const fallingFromLeft = keyframes`
    0% {
      opacity: 1;
      transform: translateX(0px) rotateZ(180deg);
    }
    100% {
      opacity: 0;
      transform: translateX(-30%) rotateZ(180deg);
    }
`;

const TIME = ".6s";
const SIZE = "150px";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ _theme }) => ({
  [`&.${classes.root}`]: {
    position: "absolute",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: `${SIZE}`,
    height: `${SIZE}`,
    "& div": {
      position: "absolute",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: `${SIZE}`,
      height: `${SIZE}`,
      opacity: 0,
      "& svg": {
        transform: "rotateZ(270deg)",
      },
      "&:nth-of-type(1)": {
        animation: `${fallingFromTop} ${TIME} linear infinite`,
      },
      "&:nth-of-type(2)": {
        animation: `${fallingFromRight} ${TIME} linear infinite`,
      },
      "&:nth-of-type(3)": {
        animation: `${fallingFromBottom} ${TIME} linear infinite`,
      },
      "&:nth-of-type(4)": {
        animation: `${fallingFromLeft} ${TIME} linear infinite`,
      },
    },
  },
}));

const RotatedArrows = () => {
  return (
    <Root className={classes.root}>
      {[...new Array(4)].map((_, i) => (
        <div key={i}>
          <KeyboardDoubleArrowDownIcon />
        </div>
      ))}
    </Root>
  );
};

export default RotatedArrows;

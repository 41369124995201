import { SET_PERCENT } from "../types";

const sendingSpinnerReducer = (state, action) => {
  if (action.type === SET_PERCENT) {
    return { ...state, percent: action.payload };
  }
  return state;
};

export default sendingSpinnerReducer;

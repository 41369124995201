import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import FaqWindow from "../faq/FaqWindow";
import { MAX_PAGE_WIDTH, ROLES } from "../constants";
import { useTranslation } from "react-i18next";
import FaqListUser from "../faq/FaqListUser";
import FaqListAdmin from "../faq/FaqListAdmin";
import api from "../utils/api";
import { checkRoles } from "../utils/keycloakService";
import { useSnackbar } from "notistack";

const PREFIX = "Faq";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: `${MAX_PAGE_WIDTH}px`,
    height: "max-content",
    padding: "30px 15px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "54px",
    },
  },
}));

const Faq = () => {
  const { t } = useTranslation();
  const [faqs, setFaqs] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const abortController = new AbortController();
    const getFaqs = async () => {
      try {
        const res = await api.getFaq(abortController);
        if (checkRoles([ROLES.ADMIN]))
          setFaqs(res.data.map((item) => ({ ...item, editing: false })));
        else setFaqs(res.data);
      } catch (err) {
        if (abortController.signal.aborted) return;
        enqueueSnackbar(t("faq.getFaqErrorMsg"), {
          variant: "error",
        });
      }
    };
    getFaqs();
    return () => abortController.abort();
  }, [enqueueSnackbar, t]);

  return (
    <Root className={classes.root}>
      <FaqWindow title={t("faq.title")}>
        {checkRoles([ROLES.ADMIN]) ? (
          <FaqListAdmin faqs={faqs} setFaqs={setFaqs} />
        ) : (
          <FaqListUser faqs={faqs} />
        )}
      </FaqWindow>
    </Root>
  );
};

export default Faq;

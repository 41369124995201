import React, { useState, useMemo, useRef, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { ButtonBase } from "@mui/material";
import {
  ROLES,
  SINGLE_LEFT_POINTING_ANGLE_QUOTATION_MARK_CHAR_CODE,
} from "../constants/index";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import FaqListAddNewIcon from "../svgIcons/FaqListAddNewIcon";
import FaqListAdminListItemNormalMode from "./FaqListAdminListItemNormalMode";
import FaqListAdminListItemEditMode from "./FaqListAdminListItemEditMode";
import api from "../utils/api";
import AuthorizedElement from "../common/auth/AuthorizedElement";
import { useSnackbar } from "notistack";

const PREFIX = "FaqListAdmin";

const classes = {
  root: `${PREFIX}-root`,
  list: `${PREFIX}-list`,
  listItemContainer: `${PREFIX}-listItemContainer`,
  bottom: `${PREFIX}-bottom`,
  backBtn: `${PREFIX}-backBtn`,
  backBtnContainer: `${PREFIX}-backBtnContainer`,
  addNewBtn: `${PREFIX}-addNewBtn`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    gap: "30px",
    flexDirection: "column",
  },
  [`& .${classes.list}`]: {
    display: "flex",
    rowGap: "15px",
    flexDirection: "column",
  },
  [`& .${classes.listItemContainer}`]: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "20px",
    borderBottom: `1px solid ${theme.palette.viduxColors.grey_3}`,
    "& .MuiListItemText-root": {
      margin: 0,
    },
  },
  [`& .${classes.bottom}`]: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: 0,
    backgroundColor: "inherit",
    gap: "30px",
  },
  [`& .${classes.backBtn}`]: {
    textDecorationLine: "underline",
    color: theme.palette.viduxColors.blue_3,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    height: "fit-content",
  },
  [`& .${classes.backBtnContainer}`]: {
    width: "100%",
    display: "flex",
    backgroundColor: theme.palette.viduxColors.grey_0,
    padding: "10px 10px 10px 30px",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  [`& .${classes.addNewBtn}`]: {
    color: "white",
    backgroundColor: theme.palette.viduxColors.green_3,
    padding: "14px 22px",
    gap: "10px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
    alignSelf: "center",
    "& svg": {
      minWidth: "20px",
      minHeight: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
      lineHeight: "25px",
      alignSelf: "flex-end",
      padding: "18px 30px 17px",
    },
    "&.Mui-disabled": {
      color: theme.palette.viduxColors.grey_4,
      backgroundColor: theme.palette.viduxColors.grey_2,
      "& svg path": {
        fill: theme.palette.viduxColors.grey_4,
      },
    },
  },
}));

const FaqListAdmin = ({ faqs, setFaqs }) => {
  const [addingNewItem, setAddingNewItem] = useState(false);

  const editContent = useRef(new Array(faqs.length).fill({}));
  const newContent = useRef();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const buttonText = useMemo(
    () =>
      [
        String.fromCharCode(
          SINGLE_LEFT_POINTING_ANGLE_QUOTATION_MARK_CHAR_CODE,
        ),
        t("faq.listBackButtonText"),
      ].join(" "),
    [t],
  );
  const addButtonDisabled = useMemo(
    () => faqs.some((item) => item.editing) || addingNewItem,
    [faqs, addingNewItem],
  );

  //existing item handling
  const onClickEnterEditMode = useCallback(
    (index) => {
      setFaqs((currentFaqs) => {
        const newFaqs = [...currentFaqs];
        newFaqs[index].editing = true;
        return newFaqs;
      });
      editContent.current[index] = { ...faqs[index] };
    },
    [faqs, setFaqs],
  );

  const onClickExitEditModeExistingItem = useCallback(
    (index) => {
      setFaqs((currentFaqs) => {
        const newFaqs = [...currentFaqs];
        newFaqs[index].editing = false;
        return newFaqs;
      });
      editContent.current[index] = {};
    },
    [setFaqs],
  );

  const onChangeAnswerExistingItem = useCallback((index, modifiedAnswer) => {
    editContent.current[index].answer = modifiedAnswer;
  }, []);

  const onChangeQuestionExistingItem = useCallback(
    (index, modifiedQuestion) => {
      editContent.current[index].question = modifiedQuestion;
    },
    [],
  );

  const onClickSaveExistingItem = useCallback(
    async (index) => {
      const updatedFaqItem = {
        question: editContent.current[index].question,
        answer: editContent.current[index].answer,
      };

      try {
        const res = await api.updateFaq(
          editContent.current[index].id,
          updatedFaqItem,
        );
        setFaqs((currentFaqs) => {
          const newFaqs = [...currentFaqs];
          newFaqs[index] = {
            ...res.data,
            editing: false,
          };
          return newFaqs;
        });
        editContent.current[index] = {};
      } catch (err) {
        enqueueSnackbar(t("faq.updateFaqErrorMsg"), {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, setFaqs, t],
  );

  const onClickDeleteExistingItem = useCallback(
    async (index) => {
      try {
        await api.deleteFaq(editContent.current[index].id);
        setFaqs((currentFaqs) => {
          const newFaqs = [...currentFaqs];
          newFaqs.splice(index, 1);
          return newFaqs;
        });
        editContent.current.splice(index, 1);
      } catch (err) {
        enqueueSnackbar(t("faq.deleteFaqErrorMsg"), {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, setFaqs, t],
  );

  //new item handling
  const onClickCloseNewItem = useCallback(() => {
    newContent.current = null;
    setAddingNewItem(false);
  }, []);

  const onChangeAnswerNewItem = useCallback((modifiedAnswer) => {
    newContent.current.answer = modifiedAnswer;
  }, []);

  const onChangeQuestionNewItem = useCallback((modifiedQuestion) => {
    newContent.current.question = modifiedQuestion;
  }, []);

  const onClickSaveNewItem = useCallback(async () => {
    try {
      const res = await api.createFaq({
        question: newContent.current.question,
        answer: newContent.current.answer,
      });
      setFaqs((currentFaqs) => {
        const newFaqs = [...currentFaqs];
        newFaqs.push({ ...res.data, editing: false });
        return newFaqs;
      });
      onClickCloseNewItem();
    } catch (err) {
      enqueueSnackbar(t("faq.createFaqErrorMsg"), {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, onClickCloseNewItem, setFaqs, t]);

  return (
    <AuthorizedElement roles={[ROLES.ADMIN]}>
      <Root className={classes.root}>
        <div className={classes.list}>
          {faqs.map(({ question, answer, editing }, idx) => (
            <div key={question} className={classes.listItemContainer}>
              {editing ? (
                <FaqListAdminListItemEditMode
                  question={question}
                  answer={answer}
                  onClickClose={() => onClickExitEditModeExistingItem(idx)}
                  onChangeQuestion={(e) =>
                    onChangeQuestionExistingItem(idx, e.target.value)
                  }
                  onChangeAnswer={(e) =>
                    onChangeAnswerExistingItem(idx, e.target.value)
                  }
                  onClickSaveItem={() => onClickSaveExistingItem(idx)}
                  onClickDeleteItem={() => onClickDeleteExistingItem(idx)}
                />
              ) : (
                <FaqListAdminListItemNormalMode
                  onClickEdit={() => onClickEnterEditMode(idx)}
                  question={question}
                  answer={answer}
                />
              )}
            </div>
          ))}
          {addingNewItem && (
            <div className={classes.listItemContainer}>
              <FaqListAdminListItemEditMode
                question={newContent.question}
                answer={newContent.answer}
                onClickClose={onClickCloseNewItem}
                onChangeQuestion={(e) =>
                  onChangeQuestionNewItem(e.target.value)
                }
                onChangeAnswer={(e) => onChangeAnswerNewItem(e.target.value)}
                onClickSaveItem={onClickSaveNewItem}
                isNewItem={true}
              />
            </div>
          )}
        </div>
        <div className={classes.bottom}>
          <ButtonBase
            className={classes.addNewBtn}
            disabled={addButtonDisabled}
            onClick={() => {
              const BLANK_ITEM = { question: "", answer: "", editing: true };
              newContent.current = { ...BLANK_ITEM };
              setAddingNewItem(true);
            }}
          >
            <FaqListAddNewIcon />
            {t("faq.addNewButtonText")}
          </ButtonBase>
          <div className={classes.backBtnContainer}>
            <ButtonBase
              className={classes.backBtn}
              onClick={() => navigate(-1)}
            >
              {buttonText}
            </ButtonBase>
          </div>
        </div>
      </Root>
    </AuthorizedElement>
  );
};

FaqListAdmin.propTypes = {
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
      editing: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setFaqs: PropTypes.func.isRequired,
};

export default FaqListAdmin;

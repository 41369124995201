import React, { useCallback, useState } from "react";
import { styled } from "@mui/material/styles";
import FileIcon from "../svgIcons/FileIcon";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import RotatedArrows from "./RotatedArrows";
import {
  isAcceptedFileType,
  isOverallSizeExceedingMaxSize,
} from "../utils/validate";
import filterNonExistingFilesByLastModified from "../utils/filterNonExistingFilesByLastModified";
import { useSnackbar } from "notistack";
import { MAX_ATTACHMENTS_SIZE } from "../constants/index";

const PREFIX = "DragAndDrop";

const classes = {
  root: `${PREFIX}-root`,
  rootDrag: `${PREFIX}-rootDrag`,
  content: `${PREFIX}-content`,
  icon: `${PREFIX}-icon`,
  text: `${PREFIX}-text`,
  browse: `${PREFIX}-browse`,
  dragEnter: `${PREFIX}-dragEnter`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.viduxColors.grey_0,
    width: "100%",
    height: "200px",
    border: `1px dashed ${theme.palette.viduxColors.grey_3}`,
    borderRadius: "4px",
    padding: "30px",
  },
  [`&.${classes.rootDrag}`]: {
    padding: "0px",
    backgroundColor: theme.palette.viduxColors.blue_0,
    border: `1px dashed ${theme.palette.viduxColors.blue_3}`,
  },
  [`& .${classes.content}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "15px",
    width: "100%",
  },
  [`& .${classes.dragEnter}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    color: theme.palette.viduxColors.blue_4,
    fontWeight: 800,
    fontSize: "14px",
    lineHeight: "19px",
    opacity: "0.5",
    "& div": {
      pointerEvents: "none",
    },
    "& svg": {
      pointerEvents: "none",
    },
  },
  [`& .${classes.icon}`]: {
    "& path": { color: theme.palette.viduxColors.grey_6 },
  },
  [`& .${classes.text}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    color: theme.palette.viduxColors.grey_6,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    "& div:nth-of-type(1)": {
      fontWeight: 800,
    },
  },
  [`& .${classes.browse}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.viduxColors.grey_2,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    padding: "6px 8px 5px",
    fontWeight: 400,
    color: theme.palette.viduxColors.grey_6,
    fontSize: "14px",
    lineHeight: "19px",
  },
}));

const DragAndDrop = ({ fileInputKey, setFormData, onChangeAddFiles }) => {
  const { t } = useTranslation();
  const [onDragEnter, setOnDragEnter] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const addFiles = useCallback(
    (fileList) => {
      if (fileList) {
        let files = Array.from(fileList).filter((file) =>
          isAcceptedFileType(file),
        );
        setFormData((prev) => {
          //filter previously added files (by 'lastModified') from the new file's list
          let filteredList = filterNonExistingFilesByLastModified(files, prev);

          if (isOverallSizeExceedingMaxSize(prev, filteredList)) {
            enqueueSnackbar(
              t("contactUs.attachmentsSizeError", {
                size: MAX_ATTACHMENTS_SIZE,
              }),
              {
                variant: "error",
              },
            );
            return prev;
          }

          return {
            ...prev,
            attachments: [...prev.attachments].concat(filteredList),
          };
        });
      }
    },
    [setFormData, enqueueSnackbar, t],
  );

  const overrideEventDefaults = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const onDropFiles = useCallback(
    (event) => {
      overrideEventDefaults(event);
      if (!event.dataTransfer) return;
      addFiles(event.dataTransfer.files);
    },
    [addFiles, overrideEventDefaults],
  );

  return (
    <Root
      className={clsx(classes.root, { [classes.rootDrag]: onDragEnter })}
      onDrop={overrideEventDefaults}
      onDragEnter={overrideEventDefaults}
      onDragLeave={overrideEventDefaults}
      onDragOver={overrideEventDefaults}
    >
      {onDragEnter ? (
        <div
          className={classes.dragEnter}
          onDrop={(e) => {
            onDropFiles(e);
            setOnDragEnter(false);
          }}
          onDragEnter={(e) => {
            overrideEventDefaults(e);
            setOnDragEnter(true);
          }}
          onDragLeave={(e) => {
            overrideEventDefaults(e);
            setOnDragEnter(false);
          }}
          onDragOver={overrideEventDefaults}
        >
          {t("contactUs.dragAndDropFilesDropMainText")}
          <RotatedArrows className={classes.icon} />
        </div>
      ) : (
        <div
          className={classes.content}
          onDrop={onDropFiles}
          onDragEnter={(e) => {
            overrideEventDefaults(e);
            setOnDragEnter(true);
          }}
        >
          <FileIcon className={classes.icon} />
          <div className={classes.text}>
            <div>{t("contactUs.dragAndDropFilesMainText")}</div>
            <div>{t("contactUs.dragAndDropFilesSecondaryText")}</div>
          </div>
          <div className={classes.browse}>
            <label>
              {t("contactUs.browseButtonText")}
              <input
                key={fileInputKey || ""}
                hidden
                type="file"
                multiple
                className="form-control"
                accept="image/*, video/*"
                name="files"
                id="files"
                onChange={onChangeAddFiles}
              />
            </label>
          </div>
        </div>
      )}
    </Root>
  );
};

export default DragAndDrop;

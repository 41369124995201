import React from "react";
import { styled } from "@mui/material/styles";
import { ButtonBase, TextField } from "@mui/material";
import FaqListCloseIcon from "../svgIcons/FaqListCloseIcon";
import { useTranslation } from "react-i18next";
import FaqListSaveIcon from "../svgIcons/FaqListSaveIcon";
import FaqListDeleteIcon from "../svgIcons/FaqListDeleteIcon";

const PREFIX = "FaqListAdminListItemEditMode";

const classes = {
  root: `${PREFIX}-root`,
  list: `${PREFIX}-list`,
  textContainer: `${PREFIX}-textContainer`,
  buttons: `${PREFIX}-buttons`,
  closeIcon: `${PREFIX}-closeIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      gap: "20px",
    },
  },
  [`& .${classes.list}`]: {
    display: "flex",
    gap: "15px",
    width: "100%",
    justifyContent: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      gap: "20px",
    },
  },
  [`& .${classes.textContainer}`]: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    gap: "10px",
    "& .MuiOutlinedInput-root": {
      fontWeight: 800,
      fontSize: "14px",
      lineHeight: "19px",
    },
  },
  [`& .${classes.buttons}`]: {
    display: "flex",
    gap: "20px",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
    "& .MuiButtonBase-root": {
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
      borderRadius: "4px",
      padding: "6px 8px 5px",
      gap: "5px",
      fontWeight: 800,
      fontSize: "14px",
      lineHeight: "19px",
    },
    "& .MuiButtonBase-root:nth-of-type(1)": {
      color: "white",
      backgroundColor: theme.palette.viduxColors.green_3,
    },
    "& .MuiButtonBase-root:nth-of-type(2)": {
      color: theme.palette.viduxColors.grey_6,
      backgroundColor: theme.palette.viduxColors.grey_2,
    },
  },
  [`& .${classes.closeIcon}`]: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
  },
}));

const FaqListAdminListItemEditMode = ({
  question,
  answer,
  onClickClose,
  onChangeQuestion,
  onChangeAnswer,
  onClickSaveItem,
  onClickDeleteItem,
  isNewItem,
}) => {
  const { t } = useTranslation();
  return (
    <Root className={classes.root}>
      <div className={classes.list}>
        <div className={classes.textContainer}>
          <TextField
            fullWidth
            label="Question"
            multiline
            defaultValue={question}
            onChange={onChangeQuestion}
          ></TextField>
          <TextField
            label="Answer"
            multiline
            fullWidth
            defaultValue={answer}
            onChange={onChangeAnswer}
          ></TextField>
        </div>
        <div className={classes.buttons}>
          <ButtonBase onClick={onClickSaveItem}>
            <FaqListSaveIcon />
            {t("faq.listSaveButtonText")}
          </ButtonBase>
          {!isNewItem && (
            <ButtonBase onClick={onClickDeleteItem}>
              <FaqListDeleteIcon />
              {t("faq.listDeleteButtonText")}
            </ButtonBase>
          )}
        </div>
      </div>
      <div className={classes.closeIcon} onClick={onClickClose}>
        <FaqListCloseIcon />
      </div>
    </Root>
  );
};

export default FaqListAdminListItemEditMode;

import React from "react";
import { styled } from "@mui/material/styles";
import FormattedTimeStamp from "../../common/FormattedTimeStamp";
import { useTranslation } from "react-i18next";
import { getStatusText } from "../../utils/adminEvents";

const PREFIX = "AdminListMainRowTable";

export const classes = {
  root: `${PREFIX}-root`,
  status: `${PREFIX}-status`,
};

const Root = styled("table")(({ theme }) => ({
  [`&.${classes.root}`]: {
    borderSpacing: 0,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    "& th": {
      fontWeight: 800,
      verticalAlign: "top",
      textAlign: "left",
    },
    "& td": {
      verticalAlign: "top",
    },
  },

  [`& .${classes.status}`]: {
    fontWeight: 800,
    color: theme.palette.viduxColors.green_3,
  },
}));

const AdminListMainRowTable = ({ row }) => {
  const { t } = useTranslation();
  return (
    <Root className={classes.root}>
      <tbody>
        <tr>
          <th>{t("home.adminTableHeaderTicketId")}</th>
          <td>{row.ticket.id}</td>
        </tr>
        <tr>
          <th>{t("home.adminTableHeaderDate")}</th>
          <td>
            <FormattedTimeStamp timeStamp={row.ticket.createdAt} />
          </td>
        </tr>
        <tr>
          <th>{t("home.adminTableHeaderName")}</th>
          <td>{row.ticket.name}</td>
        </tr>
        <tr>
          <th>{t("home.adminTableHeaderEventStatus")}</th>
          <td className={classes.status}>{getStatusText(row.events)}</td>
        </tr>
      </tbody>
    </Root>
  );
};

export default AdminListMainRowTable;

import React from "react";

const FaqListSaveIcon = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1989_15984)">
      <path
        d="M13.18 0.5H2C0.9 0.5 0 1.4 0 2.5V14.5C0 15.6 0.9 16.5 2 16.5H14C15.1 16.5 16 15.6 16 14.5V3.62L13.18 0.5ZM12 6.5C12 7.06 11.54 7.5 11 7.5H5C4.44 7.5 4 7.04 4 6.5V1.5H12V6.5ZM11 2.5H9V6.5H11V2.5Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1989_15984">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default FaqListSaveIcon;

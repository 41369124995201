import React from "react";
import { styled } from "@mui/material/styles";
import TitledWindow from "../common/TitledWindow";
import { MAX_PAGE_WIDTH } from "../constants";
import { useTranslation } from "react-i18next";
import Form from "../contactUs/Form";

const PREFIX = "ContactUs";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    maxWidth: `${MAX_PAGE_WIDTH}px`,
    height: "max-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 15px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "54px",
    },
  },
}));

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <Root className={classes.root}>
      <TitledWindow title={t("contactUs.title")}>
        <Form />
      </TitledWindow>
    </Root>
  );
};

export default ContactUs;

import React, { useCallback, memo } from "react";
import { styled } from "@mui/material/styles";
import Preview from "./Preview";
import { ButtonBase } from "@mui/material";
import FaqListDeleteIcon from "../svgIcons/FaqListDeleteIcon";
import { useTranslation } from "react-i18next";

const PREFIX = "AttachmentsListItem";

const classes = {
  root: `${PREFIX}-root`,
  details: `${PREFIX}-details`,
  fileInfo: `${PREFIX}-fileInfo`,
  buttonText: `${PREFIX}-buttonText`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
    [theme.breakpoints.up("sm")]: {},
  },
  [`& .${classes.fileInfo}`]: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    "& .MuiButtonBase-root": {
      alignItems: "center",
      justifyContent: "center",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "19px",
      color: theme.palette.viduxColors.grey_6,
      gap: "5px",
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      [`& .${classes.buttonText}`]: {
        display: "none",
      },
    },
  },
  [`& .${classes.details}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    "& div:nth-of-type(1)": {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "22px",
      color: theme.palette.viduxColors.grey_6,
      wordBreak: "break-all",
    },
    "& div:nth-of-type(2)": {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "19px",
      color: theme.palette.viduxColors.grey_5,
    },
  },
}));

const AttachmentsListItem = ({ attachment, onClickDeleteItem, index }) => {
  const { t } = useTranslation();
  const generateSizeString = useCallback(() => {
    let sizeMb = attachment.size / 1048576;
    if (sizeMb < 1) {
      return Math.floor(attachment.size / 1024) + "K | " + attachment.type;
    }
    return sizeMb.toFixed(3) + "M | " + attachment.type;
  }, [attachment]);

  return (
    <Root className={classes.root}>
      <Preview file={attachment} url={URL.createObjectURL(attachment)} />
      <div className={classes.fileInfo}>
        <div className={classes.details}>
          <div>{attachment.name}</div>
          <div>{generateSizeString()}</div>
        </div>
        <ButtonBase onClick={() => onClickDeleteItem(index)}>
          <FaqListDeleteIcon />
          <div className={classes.buttonText}>
            {t("contactUs.deleteItemButtonText")}
          </div>
        </ButtonBase>
      </div>
    </Root>
  );
};

export default memo(AttachmentsListItem);

import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  ButtonBase,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Account from "../svgIcons/Account";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { keycloak } from "../utils/keycloakService";
import { ROUTE_PATHS } from "../constants/index";
import { useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const PREFIX = "MyAccountMenu";
const BTN_W = "40px";
const BTN_H = "40px";

const classes = {
  root: `${PREFIX}-root`,
  menu: `${PREFIX}-menu`,
  menuButton: `${PREFIX}-menuButton`,
  shadow: `${PREFIX}-shadow`,
  accountIcon: `${PREFIX}-accountIcon`,
  menuListItem: `${PREFIX}-menuListItem`,
  bottomLine: `${PREFIX}-bottomLine`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    [`& .${PREFIX}-accountIcon`]: {
      "&:hover": {},
    },
  },

  [`& .${classes.menuButton}`]: {
    width: BTN_W,
    height: BTN_H,
    borderRadius: "20px",
    color: theme.palette.viduxColors.grey_5,
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px",
    transition: ".25s box-shadow",
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  [`& .${classes.shadow}`]: {
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)",
  },
}));

const MyAccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const gotToMyProfile = () => {
    handleClose();
    if (location.pathname === ROUTE_PATHS.MY_PROFILE) return;
    navigate(ROUTE_PATHS.MY_PROFILE);
  };

  const StyledMenu = styled(Menu)(({ theme }) => ({
    [`&.${classes.menu}`]: {
      "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1":
        {
          boxShadow: "none",
        },
    },
    [`& .${classes.menuListItem}`]: {
      transition: "background-color .25s",
      "&:hover": {
        backgroundColor: theme.palette.viduxColors.green_0,
      },
    },
    [`& .${classes.bottomLine}`]: {
      backgroundColor: "green",
      height: "4px",
      width: "200px",
      position: "absolute",
      bottom: "0px",
    },
  }));

  return (
    <Root className={classes.root}>
      <ButtonBase
        className={clsx(classes.menuButton, { [classes.shadow]: open })}
        id="myaccount-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Account className={classes.accountIcon} />
      </ButtonBase>

      <StyledMenu
        className={classes.menu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "myaccount-button",
        }}
      >
        <MenuItem className={classes.menuListItem} onClick={gotToMyProfile}>
          <ListItemIcon>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
          <ListItemText>{t("common.myProfile")}</ListItemText>
        </MenuItem>
        <MenuItem
          className={classes.menuListItem}
          onClick={() => keycloak.login({ action: "UPDATE_PASSWORD" })}
        >
          <ListItemIcon>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
          <ListItemText>{t("common.changePassword")}</ListItemText>
        </MenuItem>
        <MenuItem
          className={classes.menuListItem}
          onClick={() => keycloak.logout()}
        >
          <ListItemIcon>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
          <ListItemText>{t("common.logOut")}</ListItemText>
        </MenuItem>
        <span className={classes.bottomLine} />
      </StyledMenu>
    </Root>
  );
};

export default MyAccountMenu;

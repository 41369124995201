import React from "react";
import { styled } from "@mui/material/styles";
import FaqListWarningIcon from "../svgIcons/FaqListWarningIcon";

const PREFIX = "Advice";

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  text: `${PREFIX}-text`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "10px",
    width: "100%",
    padding: "15px",
    color: theme.palette.viduxColors.red_2,
    backgroundColor: theme.palette.viduxColors.red_0,
    borderRadius: "4px",
    border: `1px solid ${theme.palette.viduxColors.red_2}`,
    [theme.breakpoints.up("sm")]: {
      padding: "20px",
      gap: "20px",
    },
  },
  [`& .${classes.icon}`]: {
    height: "17px",
    width: "20px",
  },
  [`& .${classes.text}`]: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "19px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
}));

const Advice = ({ text }) => {
  return (
    <Root className={classes.root}>
      <div className={classes.icon}>
        <FaqListWarningIcon />
      </div>
      <div className={classes.text}>{text}</div>
    </Root>
  );
};

export default Advice;

import React from "react";

const AlertRedTriangle = ({ className }) => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#RedTriangle)">
      <path
        d="M10.9091 11.2368H9.09091V6.76316H10.9091V11.2368ZM10.9091 14.8158H9.09091V13.0263H10.9091V14.8158ZM0 17.5H20L10 0.5L0 17.5Z"
        fill="#AE3838"
        className="iconPath"
      />
    </g>
    <defs>
      <clipPath id="RedTriangle">
        <rect
          width="20"
          height="17"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default AlertRedTriangle;

import React, { Suspense } from "react";
import "@fontsource/nunito-sans/300.css";
import "@fontsource/nunito-sans/400.css";
import "@fontsource/nunito-sans/700.css";
import "@fontsource/nunito-sans/800.css";
import ReactDOM from "react-dom/client";
import { initKeycloak } from "./utils/keycloakService";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18next from "i18next";
import Backend from "i18next-http-backend";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

const renderApp = () => {
  i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: "en",
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default, react already safes from xss
      },
      requestOptions: {
        cache: "no-cache",
      },
    });

  return root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <Suspense fallback="loading">
          <App />
        </Suspense>
      </I18nextProvider>
    </React.StrictMode>,
  );
};

initKeycloak(renderApp);

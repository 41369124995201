import React, { useState } from "react";
import AdminListMainRow from "./AdminListMainRow";
import AdminListCollapsingRow from "./AdminListCollapsingRow";

const AdminListRowContainer = ({ row, isOdd }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AdminListMainRow open={open} setOpen={setOpen} row={row} isOdd={isOdd} />
      <AdminListCollapsingRow open={open} row={row} />
    </>
  );
};

export default AdminListRowContainer;

import React, { useCallback } from "react";
import { styled } from "@mui/material/styles";
import AdminDetailRowMapper from "./AdminDetailRowMapper";
import {
  BACKEND_ISSUE_EVENT_TYPES,
  EMAIL_EVENT_STATUS_MAP,
  ISSUE_EVENT_STATUS_MAP,
} from "../constants";
import { useTranslation } from "react-i18next";
import AdminListCollapsedRowDetailContent from "./AdminListCollapsedRowDetailContent";

const PREFIX = "AdminProcessEvents";

const classes = {
  event: `${PREFIX}-event`,
  number: `${PREFIX}-number`,
};

const Event = styled("div")(({ theme }) => ({
  [`&.${classes.event}`]: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "10px",
    alignItems: "flex-start",
    [`& .${classes.number}`]: {
      fontWeight: 800,
      fontSize: "12px",
      lineHeight: "16px",
      color: theme.palette.viduxColors.grey_6,
      [theme.breakpoints.up("sm")]: {
        fontSize: "14px",
        lineHeight: "19px",
      },
    },
  },
}));

const AdminProcessEvents = ({ row }) => {
  const { t } = useTranslation();
  const events = row.events;

  const generateTimeString = useCallback(
    (time) =>
      new Date(time).toLocaleDateString("hu") +
      " | " +
      new Date(time).toLocaleTimeString("hu"),
    [],
  );

  return (
    <AdminListCollapsedRowDetailContent
      title={t("home.adminTableTitleProcessEvents")}
    >
      {events.length > 0
        ? events.map(({ createdAt, status, payload, eventType }, idx) => {
            let statusTextMap = {
              [BACKEND_ISSUE_EVENT_TYPES.GITHUB_ISSUE]:
                ISSUE_EVENT_STATUS_MAP[status],
              [BACKEND_ISSUE_EVENT_TYPES.CONFIRMATION_EMAIL]:
                EMAIL_EVENT_STATUS_MAP[status],
              [BACKEND_ISSUE_EVENT_TYPES.INTERNAL_EMAIL]:
                EMAIL_EVENT_STATUS_MAP[status],
            };

            return (
              <Event className={classes.event} key={createdAt}>
                <div className={classes.number}>{"(" + (idx + 1) + ")"}</div>
                <AdminDetailRowMapper
                  key={createdAt}
                  table={[
                    {
                      name: t("home.adminTableDetailsTime"),
                      value: generateTimeString(createdAt),
                    },
                    {
                      name: t("home.adminTableDetailsStatus"),
                      value: statusTextMap[eventType] ?? "",
                    },
                    { name: t("home.adminTableDetailsInfo"), value: payload },
                  ]}
                />
              </Event>
            );
          })
        : "-"}
    </AdminListCollapsedRowDetailContent>
  );
};

export default AdminProcessEvents;

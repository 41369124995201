import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import AdminSortableTableHead from "./AdminSortableTableHead";
import { useTranslation } from "react-i18next";
import { ASC, DESC } from "../../constants/index";
import AdminTableRowContainer from "./AdminTableRowContainer";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import EventLogSpinner from "../EventLogSpinner";

const PREFIX = "AdminTable";

const classes = {
  root: `${PREFIX}-root`,
  tableHead: `${PREFIX}-tableHead`,
};

const Root = styled(TableContainer)(({ _theme }) => ({
  [`&.${classes.root}`]: {
    "& .MuiTableCell-root": {
      padding: "16px 20px 15px",
    },
    [`& .MuiTableBody-root`]: {
      "& .MuiTableRow-root *": {
        borderBottom: "none",
      },
    },
  },
  [`& .${classes.tableHead}`]: {
    pointerEvents: "none",
    "& .MuiTableRow-head .MuiSvgIcon-root": {
      display: "none",
    },
    "& .MuiTableRow-head .MuiTableCell-root:last-of-type": {
      "& .MuiButtonBase-root": {
        cursor: "default",
      },
      "& .MuiSvgIcon-root": {
        display: "none",
      },
    },
  },
}));

const AdminTable = ({ events, getNextLogs }) => {
  const { t } = useTranslation();
  const headCells = [
    {
      id: "ticketId",
      numeric: false,
      label: t("home.adminTableHeaderTicketId"),
    },
    {
      id: "date",
      numeric: false,
      label: t("home.adminTableHeaderDate"),
    },
    {
      id: "name",
      numeric: false,
      label: t("home.adminTableHeaderName"),
    },
    {
      id: "eventStatus",
      numeric: false,
      label: t("home.adminTableHeaderEventStatus"),
    },
    {
      id: "details",
      numeric: false,
      label: t("home.adminTableHeaderDetails"),
    },
  ];
  const [order, setOrder] = useState(ASC);
  const [orderBy, setOrderBy] = useState("ticketId");

  const handleRequestSort = (property) => {
    if (property === "details") return;
    const isAsc = orderBy === property && order === ASC;
    setOrder(isAsc ? DESC : ASC);
    setOrderBy(property);
    //navigate
  };

  return (
    <Root component={"div"} className={classes.root}>
      <InfiniteScroll
        dataLength={events.content.length ?? 0}
        next={getNextLogs}
        hasMore={events.totalPages > events.pageNumber}
        loader={<EventLogSpinner />}
      >
        <Table aria-label="collapsible table">
          <AdminSortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            className={classes.tableHead}
          />
          <TableBody>
            {events.content.map((row, idx) => (
              <AdminTableRowContainer
                key={row.ticket.id}
                row={row}
                isOdd={Boolean(idx % 2)}
              />
            ))}
          </TableBody>
        </Table>
      </InfiniteScroll>
    </Root>
  );
};

AdminTable.propTypes = {
  events: PropTypes.shape({
    content: PropTypes.arrayOf(
      PropTypes.shape({
        ticket: PropTypes.shape({
          id: PropTypes.number.isRequired,
          createdAt: PropTypes.number.isRequired,
          emailAddress: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          phoneNumber: PropTypes.string.isRequired,
          country: PropTypes.string.isRequired,
          serial: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          attachmentPaths: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        events: PropTypes.arrayOf(
          PropTypes.shape({
            helpdeskTicketId: PropTypes.number.isRequired,
            createdAt: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
            payload: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
    pageSize: PropTypes.number.isRequired,
    pageNumber: PropTypes.number.isRequired,
    allSize: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
  }),
  getNextLogs: PropTypes.func.isRequired,
};

export default AdminTable;

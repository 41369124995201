import React from "react";
import { styled } from "@mui/material/styles";
import ArrowDown from "../../svgIcons/ArrowDown";
import AdminListMainRowTable, {
  classes as adminListMainRowTableClasses,
} from "./AdminListMainRowTable";
import { getBackgroundColor } from "../../utils/adminEvents";

const PREFIX = "AdminListMainRow";

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  content: `${PREFIX}-content`,
  icon: `${PREFIX}-icon`,
  noData: `${PREFIX}-noData`,
  failed: `${PREFIX}-failed`,
  odd: `${PREFIX}-odd`,
  divider: `${PREFIX}-divider`,
  hide: `${PREFIX}-hide`,
  mirrored: `${PREFIX}-mirrored`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
    padding: "5px",
    "& table": {
      fontSize: "14px",
      lineHeight: "19px",
      color: theme.palette.viduxColors.grey_6,
      "& th": {
        textAlign: "left",
        fontWeight: 800,
        paddingRight: "4px",
      },
    },
  },
  [`& .${classes.noData}`]: {
    backgroundColor: theme.palette.viduxColors.red_0,
    [`& .${adminListMainRowTableClasses.status}`]: {
      color: theme.palette.viduxColors.red_2,
    },
  },
  [`& .${classes.failed}`]: {
    backgroundColor: theme.palette.viduxColors.orange_0,
    [`& .${adminListMainRowTableClasses.status}`]: {
      color: theme.palette.viduxColors.orange_1,
    },
  },
  [`& .${classes.odd}`]: {
    backgroundColor: theme.palette.viduxColors.grey_0,
  },
  [`& .${classes.content}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "5px",
  },
  [`& .${classes.table}`]: {
    [theme.breakpoints.up("sm")]: {},
  },
  [`& .${classes.icon}`]: {
    color: theme.palette.viduxColors.grey_6,
    display: "flex",
    justifyContent: "flex-start",
  },
  [`& .${classes.divider}`]: {
    width: "100%",
    backgroundColor: theme.palette.viduxColors.grey_3,
    height: "1px",
  },
  [`& .${classes.hide}`]: {
    backgroundColor: "inherit",
  },
  [`& .${classes.mirrored}`]: {
    transform: "rotate(180deg)",
  },
}));

const AdminListMainRow = ({ row, open, setOpen, isOdd }) => {
  return (
    <Root className={classes.root} onClick={() => setOpen((prev) => !prev)}>
      <div
        className={`${classes.content} ${getBackgroundColor(
          PREFIX,
          isOdd,
          row.events,
        )}`}
      >
        <div className={classes.table}>
          <AdminListMainRowTable row={row} />
        </div>
        <div className={classes.icon}>
          {open ? <ArrowDown className={classes.mirrored} /> : <ArrowDown />}
        </div>
      </div>
      <div className={`${classes.divider} ${open ? classes.hide : ""}`} />
    </Root>
  );
};

export default AdminListMainRow;

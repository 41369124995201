import {
  BACKEND_EMAIL_EVENT_STATUS,
  BACKEND_ISSUE_EVENT_STATUS,
  BACKEND_ISSUE_EVENT_TYPES,
  EMAIL_EVENT_STATUS_MAP,
  ISSUE_EVENT_STATUS_MAP,
} from "../constants";

export const getBackgroundColor = (PREFIX, isOdd, events) => {
  let cssClass = isOdd ? PREFIX + "-odd" : "";
  if (events.length === 0) cssClass = PREFIX + "-noData";
  else {
    events.forEach((event) => {
      if (
        ![
          BACKEND_ISSUE_EVENT_STATUS.SUCCESS,
          BACKEND_EMAIL_EVENT_STATUS.SUCCESS,
        ].includes(event.status)
      )
        cssClass = PREFIX + "-failed";
    });
  }

  return cssClass;
};

export const getStatusText = (events) => {
  let statusText = "No event data";

  const githubIssueEventStatus = events.find(
    (event) => event.eventType === BACKEND_ISSUE_EVENT_TYPES.GITHUB_ISSUE,
  )?.status;

  if (githubIssueEventStatus)
    statusText = ISSUE_EVENT_STATUS_MAP[githubIssueEventStatus];

  if (githubIssueEventStatus === BACKEND_ISSUE_EVENT_STATUS.SUCCESS) {
    events.forEach((event) => {
      if (
        [
          BACKEND_ISSUE_EVENT_TYPES.CONFIRMATION_EMAIL,
          BACKEND_ISSUE_EVENT_TYPES.INTERNAL_EMAIL,
        ].includes(event.eventType) &&
        event.status === BACKEND_EMAIL_EVENT_STATUS.FAILED
      )
        statusText = EMAIL_EVENT_STATUS_MAP[event.status];
    });
  }

  return statusText;
};

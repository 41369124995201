import axios from "axios";
import { getValidToken } from "./keycloakService";

const defaultHeaderConfig = async () => {
  const token = await getValidToken();
  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
};
const sendFormHeaderConfig = async () => {
  const token = await getValidToken();
  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };
};

const authInstance = axios.create({
  baseURL: process.env.REACT_APP_KEYCLOAK_URL,
  timeout: 5000,
});
authInstance.interceptors.request.use(async (config) => {
  config.headers = await Promise.resolve(defaultHeaderConfig());
  return config;
});

const helpdeskServerInstance = axios.create({
  baseURL: process.env.REACT_APP_HELPDESK_SERVER,
  timeout: 5000,
});
helpdeskServerInstance.interceptors.request.use(async (config) => {
  config.headers = await Promise.resolve(defaultHeaderConfig());
  return config;
});

const sendFormInstance = axios.create({
  baseURL: process.env.REACT_APP_HELPDESK_SERVER,
  timeout: 5000,
});
sendFormInstance.interceptors.request.use(async (config) => {
  config.headers = await Promise.resolve(sendFormHeaderConfig());
  return config;
});

const api = {
  getUserInfo: async ({ signal }) => {
    return authInstance({
      method: "GET",
      url: `/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/userinfo`,
      signal,
    });
  },
  getFaq: async ({ signal }) => {
    return helpdeskServerInstance({
      method: "GET",
      url: "/faq",
      signal,
    });
  },
  createFaq: async (faqItem) => {
    return helpdeskServerInstance({
      method: "POST",
      url: "/faq",
      data: faqItem,
    });
  },
  updateFaq: async (id, faqItem) => {
    return helpdeskServerInstance({
      method: "PUT",
      url: `/faq/${id}`,
      data: faqItem,
    });
  },
  deleteFaq: async (id) => {
    return helpdeskServerInstance({
      method: "DELETE",
      url: `/faq/${id}`,
    });
  },
  getServerLogs: async (params, { signal }) =>
    helpdeskServerInstance({
      method: "GET",
      url: "/tickets",
      timeout: 20000,
      params,
      signal,
    }),

  sendForm: async (formData, progress) =>
    sendFormInstance({
      method: "POST",
      url: "/tickets",
      timeout: 2000000,
      data: formData,
      onUploadProgress: (progressEvent) => progress(progressEvent),
    }),
};

export default api;

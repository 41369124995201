export const DEFAULT_LANGUAGE = "hu";
export const ACCEPTED_FILE_TYPES = ["image", "video"];

export const ROUTE_PATHS = {
  HOME: "/",
  FAQ: "/faq",
  CONTACT_US: "/contactus",
  MY_PROFILE: "/myprofile",
  SUCCESS: "/success",
};

export const ROLES = {
  ADMIN: "ADMIN_ROLE",
  REPORTER: "REPORTER_ROLE",
};

export const SINGLE_RIGHT_POINTING_ANGLE_QUOTATION_MARK_CHAR_CODE = "0x203A";
export const SINGLE_LEFT_POINTING_ANGLE_QUOTATION_MARK_CHAR_CODE = "0x2039";

export const MAX_PAGE_WIDTH = 1066;

export const COUNTRY_OPTIONS = [
  { VALUE: "al", LABEL: "OTP-Albania" },
  { VALUE: "bg", LABEL: "OTP-Bulgaria" },
  { VALUE: "hr", LABEL: "OTP-Croatia" },
  { VALUE: "hu", LABEL: "OTP-Hungary" },
  { VALUE: "md", LABEL: "OTP-Moldova" },
  { VALUE: "me", LABEL: "OTP-Montenegro" },
  { VALUE: "ro", LABEL: "OTP-Romania" },
  { VALUE: "rs", LABEL: "OTP-Serbia" },
  { VALUE: "ru", LABEL: "OTP-Russia" },
  { VALUE: "si", LABEL: "OTP-Slovenia" },
  { VALUE: "sk", LABEL: "OTP-Slovakia" },
  { VALUE: "ua", LABEL: "OTP-Ukraine" },
];

export const MAX_ATTACHMENTS_SIZE = 25;
export const MAX_ATTACHMENTS_SIZE_BYTE = 26214400; //25MB
export const FORM_DESCRIPTION_MIN_CHARACTERS = 50;

//sort orders
export const ASC = "asc";
export const DESC = "desc";

export const BACKEND_ISSUE_EVENT_TYPES = {
  GITHUB_ISSUE: "Github issue event",
  CONFIRMATION_EMAIL: "Confirmation email event",
  INTERNAL_EMAIL: "Internal email event",
};

export const BACKEND_ISSUE_EVENT_STATUS = {
  SUCCESS: "SUCCESS",
  ATTACHMENT_UPLOAD_FAILED: "ATTACHMENT_UPLOAD_FAILED",
  ISSUE_SUBMIT_FAILED: "ISSUE_SUBMIT_FAILED",
};

export const ISSUE_EVENT_STATUS_MAP = {
  [BACKEND_ISSUE_EVENT_STATUS.SUCCESS]: "Success",
  [BACKEND_ISSUE_EVENT_STATUS.ATTACHMENT_UPLOAD_FAILED]:
    "Attachment upload failed",
  [BACKEND_ISSUE_EVENT_STATUS.ISSUE_SUBMIT_FAILED]: "Issue submit failed",
};

export const BACKEND_EMAIL_EVENT_STATUS = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};
export const EMAIL_EVENT_STATUS_MAP = {
  [BACKEND_EMAIL_EVENT_STATUS.SUCCESS]: "Success",
  [BACKEND_EMAIL_EVENT_STATUS.FAILED]: "Email sending failed",
};

//video preview settings
export const PLAY_DURATION_MSEC = 500;
export const SKIP_INTERVAL_SEC = 3;

import PropTypes from "prop-types";
import React from "react";

const FormattedTimeStamp = ({ timeStamp }) => {
  return (
    <div>
      {timeStamp ? (
        <>
          <span>{new Date(timeStamp).toLocaleDateString("hu")}</span>
          <span>&nbsp;|&nbsp;</span>
          <span>{new Date(timeStamp).toLocaleTimeString("hu")}</span>
        </>
      ) : (
        <span>-</span>
      )}
    </div>
  );
};

FormattedTimeStamp.propTypes = {
  timeStamp: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
};

export default FormattedTimeStamp;

import React, { useCallback, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { COUNTRY_OPTIONS } from "../constants/index";
import clsx from "clsx";

export const PREFIX = "SystemInformation";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  input: `${PREFIX}-input`,
  countryLabel: `${PREFIX}-countryLabel`,
  countrySelect: `${PREFIX}-countrySelect`,
  error: `${PREFIX}-error`,
};

const Root = styled("div")(({ _theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    flexDirection: "column",
  },
  [`& .${classes.input}`]: {
    gap: "20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

const SystemInformation = ({ formData, setFormData, validation }) => {
  const { t } = useTranslation();
  const countryInit = useRef(true);
  const serialNrInit = useRef(true);

  const onChangeSelectCountry = useCallback(
    (event) => {
      setFormData((prev) => ({ ...prev, country: event.target.value }));
      countryInit.current = false;
    },
    [setFormData],
  );

  const onChangeSerialNumber = useCallback(
    (event) => {
      setFormData((prev) => ({ ...prev, serialNumber: event.target.value }));
      serialNrInit.current = false;
    },
    [setFormData],
  );

  return (
    <Root className={classes.root}>
      <div className={classes.title}>
        {t("contactUs.systemInformationTitle")}
      </div>
      <div className={classes.input}>
        <FormControl
          fullWidth
          className={clsx({
            [classes.error]: !validation.isValidCountry && !countryInit.current,
          })}
        >
          <InputLabel
            className={classes.countryLabel}
            id={classes.countryLabel}
          >
            {t("contactUs.country")}
          </InputLabel>
          <Select
            aria-label="select country"
            labelId={classes.countryLabel}
            label={t("contactUs.country")}
            onChange={onChangeSelectCountry}
            value={formData.country}
          >
            {COUNTRY_OPTIONS.map((OPTION) => (
              <MenuItem key={OPTION.VALUE} value={OPTION.VALUE}>
                {OPTION.LABEL}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          value={formData.serialNumber}
          aria-label="serial number input field"
          label={t("contactUs.serialNumber")}
          onChange={onChangeSerialNumber}
          fullWidth
          className={clsx({
            [classes.error]:
              !validation.isValidSerialNumber && !serialNrInit.current,
          })}
        />
      </div>
    </Root>
  );
};

export default SystemInformation;

import React, { useMemo, useCallback, useContext } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  COUNTRY_OPTIONS,
  ROUTE_PATHS,
  SINGLE_LEFT_POINTING_ANGLE_QUOTATION_MARK_CHAR_CODE,
} from "../constants/index";
import SendIcon from "../svgIcons/SendIcon";
import api from "../utils/api";
import { useSnackbar } from "notistack";
import SendingSpinnerContext from "../context/sendingSpinnerContext/sendingSpinnerContext";

const PREFIX = "FormButtons";

const classes = {
  root: `${PREFIX}-root`,
  backBtn: `${PREFIX}-backBtn`,
  sendBtn: `${PREFIX}-sendBtn`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: "inherit",
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "15px",
    padding: 0,
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.viduxColors.grey_0,
      flexDirection: "row",
      padding: "10px 10px 10px 30px",
    },
  },
  [`& .${classes.sendBtn}`]: {
    color: "white",
    backgroundColor: theme.palette.viduxColors.green_3,
    padding: "14px 22px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    gap: "10px",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
    "& svg": {
      minWidth: "20px",
      minHeight: "17px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
      lineHeight: "25px",
      padding: "18px 30px 17px",
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.viduxColors.grey_3,
    },
  },
  [`& .${classes.backBtn}`]: {
    height: "fit-content",
    textDecorationLine: "underline",
    color: theme.palette.viduxColors.blue_3,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "19px",
  },
}));

const FormButtons = ({ isValidForm, formData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const sendingSpinnerState = useContext(SendingSpinnerContext);
  const { setPercent } = sendingSpinnerState;
  const buttonText = useMemo(
    () =>
      [
        String.fromCharCode(
          SINGLE_LEFT_POINTING_ANGLE_QUOTATION_MARK_CHAR_CODE,
        ),
        t("faq.listBackButtonText"),
      ].join(" "),
    [t],
  );

  const onProgress = useCallback(
    (progressEvent) => {
      const uploadedPercentage = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      setPercent(uploadedPercentage);
    },
    [setPercent],
  );

  const handleClick = (event) => {
    event.currentTarget.disabled = true;
    sendForm();
  };

  const sendForm = async () => {
    const sendFormData = new FormData();
    if (formData.attachments) {
      for (let file of formData.attachments) {
        sendFormData.append("files", file);
      }
    }

    const countryLabel = COUNTRY_OPTIONS.filter(
      (item) => item.VALUE === formData.country,
    )[0].LABEL;
    const fields = {
      country: countryLabel,
      serial: formData.serialNumber,
      name: formData.name,
      emailAddress: formData.emailAddress,
      phoneNumber: formData.phone,
      description: formData.description,
    };
    const blob = new Blob([JSON.stringify(fields)], {
      type: "application/json",
    });

    sendFormData.append("params", blob);

    try {
      await api.sendForm(sendFormData, onProgress);
      navigate(ROUTE_PATHS.SUCCESS);
    } catch (err) {
      enqueueSnackbar(t("contactUs.sendFormErrorMsg"), {
        variant: "error",
      });
      setPercent(0);
    }
  };

  return (
    <Root className={classes.root}>
      <ButtonBase className={classes.backBtn} onClick={() => navigate(-1)}>
        {buttonText}
      </ButtonBase>
      <ButtonBase
        className={classes.sendBtn}
        onClick={handleClick}
        disabled={!isValidForm}
      >
        <SendIcon />
        {t("contactUs.sendButtonText")}
      </ButtonBase>
    </Root>
  );
};

export default FormButtons;

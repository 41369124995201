import React from "react";

const FaqMenuIcon = ({ className }) => (
  <svg
    className={className}
    width="62"
    height="62"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.8 28.6H30.2V27H31.8V28.6ZM31.8 35H30.2V30.2H31.8V35ZM31 23C26.6 23 23 26.6 23 31C23 35.4 26.6 39 31 39C35.4 39 39 35.4 39 31C39 26.6 35.4 23 31 23Z"
      fill="#2D7CAA"
    />
    <path
      d="M31 61C47.5685 61 61 47.5685 61 31C61 14.4315 47.5685 1 31 1C14.4315 1 1 14.4315 1 31C1 47.5685 14.4315 61 31 61Z"
      fill="#DAF7DD"
      stroke="#38AE4C"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M6.5 41.2C9.5 48.3 15.4 53.8 22.7 56.2L6.5 41.2Z" fill="#DAF7DD" />
    <path
      d="M6.5 41.2C9.5 48.3 15.4 53.8 22.7 56.2"
      stroke="#38AE4C"
      strokeMiterlimit="10"
    />
    <path
      d="M57.6 31C57.6 16.3 45.7 4.40002 31 4.40002L57.6 31Z"
      fill="#DAF7DD"
    />
    <path
      d="M57.6 31C57.6 16.3 45.7 4.40002 31 4.40002"
      stroke="#38AE4C"
      strokeMiterlimit="10"
    />
    <path
      d="M31 22C29.3 22 28 20.7 28 19C28 17.3 29.3 16 31 16C32.7 16 34 17.3 34 19C34 20.7 32.7 22 31 22Z"
      fill="white"
      stroke="#38AE4C"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M31 46C29.3 46 28 44.7 28 43V31C28 29.3 29.3 28 31 28C32.7 28 34 29.3 34 31V43C34 44.7 32.7 46 31 46Z"
      fill="white"
      stroke="#38AE4C"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default FaqMenuIcon;

import React, { memo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ROLES, ROUTE_PATHS } from "../constants";
import Home from "../pages/Home";
import { AuthorizedRoute } from "../common/auth/AuthorizedRoute";
import { styled } from "@mui/material/styles";
import { NAV_BAR_H } from "../common/Navbar";
import ContactUs from "../pages/ContactUs";
import Faq from "../pages/Faq";
import Success from "../pages/Success";
import MyProfile from "../pages/MyProfile";

const PREFIX = "AppRoutes";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ _theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: "100vh",
    paddingTop: `${NAV_BAR_H}px`,
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

const AppRoutes = memo(() => {
  return (
    <Root className={classes.root}>
      <Routes>
        <Route
          path={ROUTE_PATHS.CONTACT_US}
          element={
            <AuthorizedRoute roles={[ROLES.REPORTER]}>
              <ContactUs />
            </AuthorizedRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.FAQ}
          element={
            <AuthorizedRoute roles={[ROLES.REPORTER]}>
              <Faq />
            </AuthorizedRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.SUCCESS}
          element={
            <AuthorizedRoute roles={[ROLES.REPORTER]}>
              <Success />
            </AuthorizedRoute>
          }
        />
        <Route path={ROUTE_PATHS.MY_PROFILE} element={<MyProfile />} />
        <Route path={ROUTE_PATHS.HOME} element={<Home />} />
        <Route path={"/*"} element={<Navigate to={ROUTE_PATHS.HOME} />} />
      </Routes>
    </Root>
  );
});

export default AppRoutes;

import React, { useState } from "react";
import AdminTableMainRow from "./AdminTableMainRow";
import AdminTableCollapsingRow from "./AdminTableCollapsingRow";

const AdminTableRowContainer = ({ row, isOdd }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AdminTableMainRow
        open={open}
        setOpen={setOpen}
        row={row}
        isOdd={isOdd}
      />
      <AdminTableCollapsingRow open={open} row={row} />
    </>
  );
};

export default AdminTableRowContainer;

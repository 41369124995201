import {
  SnackbarProvider as NotistackSnackbarProvider,
  MaterialDesignContent,
} from "notistack";
import { styled } from "@mui/system";
import AlertRedTriangle from "../svgIcons/AlertRedTriangle";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    "&.notistack-MuiContent-error": {
      color: theme.palette.viduxColors.red_2,
      backgroundColor: theme.palette.viduxColors.red_0,
      border: `1px solid ${theme.palette.viduxColors.red_2}`,
      paddingRight: "20px",
      boxSizing: "border-box",
      borderRadius: "4px",
      "& svg": {
        marginRight: "20px",
        marginTop: "6px",
        marginBottom: "6px",
      },
    },
  }),
);

const SnackbarProvider = ({ children }) => {
  return (
    <NotistackSnackbarProvider
      maxSnack={5}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      iconVariant={{
        error: <AlertRedTriangle />,
      }}
      Components={{
        error: StyledMaterialDesignContent,
      }}
      preventDuplicate
    >
      {children}
    </NotistackSnackbarProvider>
  );
};

export default SnackbarProvider;

import React from "react";
import { Navigate } from "react-router-dom";
import { keycloak } from "../../utils/keycloakService";
import PropTypes from "prop-types";
import { ROUTE_PATHS } from "../../constants";

export function AuthorizedRoute({ children, roles }) {
  const isAuthorized = (routeRoles) => {
    if (keycloak && routeRoles) {
      return routeRoles.some((r) => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }
    return false;
  };
  return isAuthorized(roles) ? children : <Navigate to={ROUTE_PATHS.HOME} />;
}

AuthorizedRoute.propTypes = {
  roles: PropTypes.array,
};

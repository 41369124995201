import PropTypes from "prop-types";
import { useMediaQuery, useTheme } from "@mui/material";

const ComponentSwitcher = ({
  mobileViewPortComponent,
  pcViewPortComponent,
  atBreakPoint,
}) => {
  const theme = useTheme();
  const ABOVE_BREAKPOINT = theme.breakpoints.up(atBreakPoint).split(" ")[1];
  const IS_ABOVE_BREAKPOINT = useMediaQuery(ABOVE_BREAKPOINT);

  if (IS_ABOVE_BREAKPOINT) {
    return pcViewPortComponent;
  } else return mobileViewPortComponent;
};

ComponentSwitcher.propTypes = {
  atBreakPoint: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired,
  mobileViewPortComponent: PropTypes.node.isRequired,
  pcViewPortComponent: PropTypes.node.isRequired,
};

export default ComponentSwitcher;

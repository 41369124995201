import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import AdminTable from "./pc/AdminTable";
import ComponentSwitcher from "../common/ComponentSwitcher";
import AdminList from "./phone/AdminList";
import api from "../utils/api";
import { useSnackbar } from "notistack";

const PREFIX = "AdminContent";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "max-content",
    filter: "drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.1))",
  },
  [`& .${classes.title}`]: {
    padding: "12px 15px 11px",
    color: "white",
    backgroundColor: theme.palette.viduxColors.green_3,
    borderRadius: "4px 4px 0px 0px",
    width: "100%",
    fontWeight: 800,
    fontSize: "18px",
    lineHeight: "25px",
    [theme.breakpoints.up("sm")]: {
      padding: "12px 30px 11px",
      fontSize: "20px",
      lineHeight: "27px",
    },
  },
  [`& .${classes.content}`]: {
    width: "100%",
    height: "max-content",
    padding: "15px 10px",
    backgroundColor: "white",
    borderRadius: "0px 0px 4px 4px",
    [theme.breakpoints.up("sm")]: {
      padding: "30px",
    },
  },
}));

const AdminContent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const defaultPageSize = 20;

  const [events, setEvents] = useState({
    content: [],
    pageSize: defaultPageSize,
    pageNumber: 1,
    allSize: 0,
    totalPages: 1,
  });

  useEffect(() => {
    const abortController = new AbortController();
    const getEvents = async () => {
      try {
        const res = await api.getServerLogs(
          {
            pageNumber: 1,
            pageSize: defaultPageSize,
          },
          abortController,
        );
        setEvents(res.data);
      } catch (err) {
        if (abortController.signal.aborted) return;
        enqueueSnackbar(t("home.getServerLogsErrorMsg"), {
          variant: "error",
        });
      }
    };
    getEvents();
    return () => abortController.abort();
  }, [enqueueSnackbar, t]);

  const getNextLogs = async (currentEvents) => {
    const abortController = new AbortController();
    try {
      const res = await api.getServerLogs(
        {
          pageNumber: currentEvents.pageNumber + 1,
          pageSize: defaultPageSize,
        },
        abortController,
      );
      setEvents((current) => {
        return {
          content: current.content.concat(res.data.content),
          pageSize: res.data.pageSize,
          pageNumber: res.data.pageNumber,
          allSize: res.data.allSize,
          totalPages: res.data.totalPages,
        };
      });
    } catch (err) {
      if (abortController.signal.aborted) return;
      enqueueSnackbar(t("home.getServerLogsErrorMsg"), {
        variant: "error",
      });
    }
  };

  return (
    <Root className={classes.root}>
      <div className={classes.title}>{t("eventList.title")}</div>
      <div className={classes.content}>
        <ComponentSwitcher
          pcViewPortComponent={
            <AdminTable
              events={events}
              getNextLogs={() => getNextLogs(events)}
            />
          }
          mobileViewPortComponent={
            <AdminList
              events={events}
              getNextLogs={() => getNextLogs(events)}
            />
          }
          atBreakPoint="sm"
        />
      </div>
    </Root>
  );
};

export default AdminContent;

import Keycloak from "keycloak-js";
import { DEFAULT_LANGUAGE } from "../constants";

export const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

export const initKeycloak = (onAuthenticatedCallback) => {
  keycloak
    .init({
      onLoad: "login-required",
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        keycloak.login();
      }
    });
};

export const getValidToken = async () => {
  try {
    await keycloak.updateToken();
  } catch (err) {
    keycloak.clearToken();
  }
  return keycloak.token;
};

export const getUserLocale = () => {
  return keycloak.idTokenParsed.locale || DEFAULT_LANGUAGE;
};

export const getUserId = () => {
  return keycloak.subject;
};

export const checkRoles = (roles) =>
  roles.some((role) => {
    const realm = keycloak.hasRealmRole(role);
    const resource = keycloak.hasResourceRole(role);
    return realm || resource;
  });

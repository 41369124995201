import { keycloak } from "../../utils/keycloakService";

export default function AuthorizedElement({ roles, children }) {
  const isAuthorized = () => {
    if (keycloak && roles) {
      return roles.some((r) => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }
    return false;
  };
  return isAuthorized() && children;
}

import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "AdminListCollapsedRowDetailContent";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    gap: "10px",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  [`& .${classes.title}`]: {
    color: theme.palette.viduxColors.blue_4,
    fontWeight: 800,
    fontSize: "18px",
    lineHeight: "25px",
    [theme.breakpoints.up("sm")]: {
      fontWeight: 800,
      fontSize: "20px",
      lineHeight: "27px",
    },
  },
  [`& .${classes.content}`]: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    gap: "5px",
  },
}));

const AdminListCollapsedRowDetailContent = ({ title, children }) => {
  return (
    <Root className={classes.root}>
      <div className={classes.title}>{title}</div>
      <div className={classes.content}>{children}</div>
    </Root>
  );
};

export default AdminListCollapsedRowDetailContent;

import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import AdminListRowContainer from "./AdminListRowContainer";
import InfiniteScroll from "react-infinite-scroll-component";
import EventLogSpinner from "../EventLogSpinner";

const PREFIX = "AdminList";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
};

const Root = styled("div")(({ _theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
  },
  [`& .${classes.content}`]: {},
}));

const AdminList = ({ events, getNextLogs }) => {
  return (
    <InfiniteScroll
      dataLength={events.content.length ?? 0}
      next={getNextLogs}
      hasMore={events.totalPages > events.pageNumber}
      loader={<EventLogSpinner />}
    >
      <Root className={classes.root}>
        {events.content.map((row, idx) => (
          <AdminListRowContainer
            key={row.ticket.id}
            row={row}
            isOdd={Boolean(idx % 2)}
          />
        ))}
      </Root>
    </InfiniteScroll>
  );
};

AdminList.propTypes = {
  events: PropTypes.shape({
    content: PropTypes.arrayOf(
      PropTypes.shape({
        ticket: PropTypes.shape({
          id: PropTypes.number.isRequired,
          createdAt: PropTypes.number.isRequired,
          emailAddress: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          phoneNumber: PropTypes.string.isRequired,
          country: PropTypes.string.isRequired,
          serial: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          attachmentPaths: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        events: PropTypes.arrayOf(
          PropTypes.shape({
            helpdeskTicketId: PropTypes.number.isRequired,
            createdAt: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
            payload: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
    pageSize: PropTypes.number.isRequired,
    pageNumber: PropTypes.number.isRequired,
    allSize: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
  }),
  getNextLogs: PropTypes.func.isRequired,
};

export default AdminList;

import React from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from "@mui/material/styles";
import Navbar from "./common/Navbar";
import AppRoutes from "./routes/AppRoutes";
import { useTranslation } from "react-i18next";
import { enUS } from "@mui/material/locale";
import LoggedInUserState from "./context/loggedInUser/LoggedInUserState";
import AppInitializer from "./common/AppInitializer";
import SnackbarProvider from "./common/SnackbarProvider";
import SendingSpinnerState from "./context/sendingSpinnerContext/SendingSpinnerState";

const muiLocale = {
  en: enUS,
};

function App() {
  const { i18n } = useTranslation();
  const theme = createTheme(
    {
      components: {
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: "12px",
            },
          },
        },
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true,
          },
          styleOverrides: {
            root: {
              fontFamily: "inherit",
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "22px",
            },
          },
        },
      },
      typography: {
        fontFamily: [
          "Nunito Sans",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1366,
          xl: 1920,
        },
      },
      palette: {
        primary: {
          main: "#38AE4C",
        },
        secondary: { main: "#ffecec" },
        viduxColors: {
          blue_0: "#E2F2FC",
          blue_1: "#8AAEC2",
          blue_2: "#CBECFF",
          blue_3: "#2D7CAA",
          blue_4: "#406479",
          grey_0: "#f4f4f4",
          grey_1: "#ebebeb",
          grey_2: "#e4e4e4",
          grey_3: "#cbcbcb",
          grey_4: "#a7a7a7",
          grey_5: "#8b8b8b",
          grey_6: "#565656",
          green_0: "#DAF7DD", //hover
          green_1: "#A0ECAD",
          green_2: "#48C35D",
          green_3: "#38AE4C",
          red_0: "#ffecec",
          red_1: "#ca4848",
          red_2: "#ae3838",
          orange_1: "#C78017",
          orange_0: "#FFF4E3",
        },
      },
    },
    muiLocale[i18n.language],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <CssBaseline />
          <LoggedInUserState>
            <SendingSpinnerState>
              <>
                <AppInitializer />
                <BrowserRouter>
                  <Navbar />
                  <AppRoutes />
                </BrowserRouter>
              </>
            </SendingSpinnerState>
          </LoggedInUserState>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;

import React from "react";
import { styled } from "@mui/material/styles";
import { MAX_PAGE_WIDTH } from "../constants/index";
import ReporterMenuContainer from "./ReporterMenuContainer";
import { useTranslation } from "react-i18next";

const PREFIX = "ReporterContent";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: `${MAX_PAGE_WIDTH}px`,
    height: "100%",
    padding: "0px",
    gap: "15px",
    [theme.breakpoints.up("sm")]: {
      gap: "60px",
    },
  },
  [`& .${classes.title}`]: {
    color: theme.palette.viduxColors.blue_4,
    textAlign: "center",
    fontWeight: 800,
    fontSize: "30px",
    lineHeight: "41px",

    overflowWrap: "break-word",
    inlineSize: "100%",
    [theme.breakpoints.up("sm")]: {
      fontSize: "39px",
      lineHeight: "53px",
    },
  },
  [`& .${classes.content}`]: {},
}));

const ReporterContent = () => {
  const { t } = useTranslation();
  return (
    <Root className={classes.root}>
      <div className={classes.title}>{t("home.welcome")}</div>
      <ReporterMenuContainer />
    </Root>
  );
};

export default ReporterContent;

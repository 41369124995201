import React from "react";

const ArrowDown = ({ className }) => (
  <svg
    className={className}
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.09995 0.300048L6.69995 4.90005L11.3 0.300049L12.7 1.70005L6.69995 7.70005L0.699951 1.70005L2.09995 0.300048Z"
      fill="#565656"
    />
  </svg>
);

export default ArrowDown;

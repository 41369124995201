import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import { ButtonBase } from "@mui/material";
import { SINGLE_RIGHT_POINTING_ANGLE_QUOTATION_MARK_CHAR_CODE } from "../constants";
import { useTranslation } from "react-i18next";

const PREFIX = "ReporterMenuContainer";

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "max-content",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    padding: "20px",
    width: "100%",
    flexGrow: 1,
    backgroundColor: "white",
    gap: "10px",
    [theme.breakpoints.up("sm")]: {
      padding: "30px",
      gap: "15px",
    },
  },
  [`& .${classes.icon}`]: {
    backgroundColor: "inherit",
  },
  [`& .${classes.title}`]: {
    color: theme.palette.viduxColors.green_3,
    fontWeight: 800,
    fontSize: "18px",
    lineHeight: "25px",
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "27px",
    },
  },
  [`& .${classes.description}`]: {
    color: theme.palette.viduxColors.grey_6,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
  [`& .${classes.button}`]: {
    color: "white",
    backgroundColor: theme.palette.viduxColors.green_3,
    padding: "7px 15px",
    gap: "10px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
  },
}));

const ReporterMenuItem = ({ icon: Icon, title, description, onClick }) => {
  const { t } = useTranslation();
  const buttonText = useMemo(
    () =>
      [
        t("home.reporterMenuItemMainButtonText"),
        String.fromCharCode(
          SINGLE_RIGHT_POINTING_ANGLE_QUOTATION_MARK_CHAR_CODE,
        ),
      ].join(" "),
    [t],
  );
  return (
    <Root className={classes.root}>
      <div className={classes.title}>
        <Icon className={classes.icon} />
        {title}
      </div>
      <div className={classes.description}>{description}</div>
      <ButtonBase className={classes.button} onClick={onClick}>
        {buttonText}
      </ButtonBase>
    </Root>
  );
};

export default ReporterMenuItem;

import React, { memo, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { SKIP_INTERVAL_SEC, PLAY_DURATION_MSEC } from "../constants";

const PREFIX = "Preview";

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  video: `${PREFIX}-video`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    minWidth: "90px",
    minHeight: "90px",
    width: "90px",
    height: "90px",
  },
  [`& .${classes.image}`]: {
    width: "90px",
    height: "90px",
    objectFit: "contain",
    backgroundColor: theme.palette.viduxColors.grey_1,
    borderRadius: "4px",
    border: `1px solid ${theme.palette.viduxColors.grey_3}`,
  },
  [`& .${classes.video}`]: {
    width: "90px",
    height: "90px",
    backgroundColor: theme.palette.viduxColors.grey_1,
    borderRadius: "4px",
    objectFit: "contain",
    "& video": {
      width: "100%",
      height: "100%",
    },
  },
}));

const Preview = ({ file, url }) => {
  const typeIsImage = file.type.split("/")[0] === "image";
  const videoRef = useRef();
  useEffect(() => {
    let id;
    let videoEl = videoRef.current;
    if (videoEl) {
      id = setInterval(() => {
        videoEl.currentTime += SKIP_INTERVAL_SEC;
        let playPromise = videoEl.play();
        playPromise.catch((_) => {
          videoEl.pause();
        });
      }, PLAY_DURATION_MSEC);
    }
    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [videoRef]);

  return (
    <Root className={classes.root}>
      {typeIsImage ? (
        <img className={classes.image} src={url} alt="" />
      ) : (
        <div className={classes.video}>
          <video
            ref={videoRef}
            src={url}
            autoPlay={true}
            muted={true}
            loop
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
      )}
    </Root>
  );
};

export default memo(Preview);

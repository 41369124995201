import React from "react";
import AdminDetailRowMapper from "./AdminDetailRowMapper";
import { useTranslation } from "react-i18next";
import AdminListCollapsedRowDetailContent from "./AdminListCollapsedRowDetailContent";

const AdminPersonalInformation = ({ row }) => {
  const { t } = useTranslation();
  const table = [
    { name: t("home.adminTableDetailsName"), value: row.ticket.name },
    {
      name: t("home.adminTableDetailsEmailAddress"),
      value: row.ticket.emailAddress,
      isUnderlined: true,
    },
    {
      name: t("home.adminTableDetailsPhoneNumber"),
      value: row.ticket.phoneNumber || "-",
    },
    { name: t("home.adminTableDetailsMessage"), value: row.ticket.description },
    {
      name: t("home.adminTableDetailsAttachments"),
      value: row.ticket?.attachmentPaths?.length || "-",
    },
  ];
  return (
    <AdminListCollapsedRowDetailContent
      title={t("home.adminTableTitlePersonalInformation")}
    >
      <AdminDetailRowMapper table={table} />
    </AdminListCollapsedRowDetailContent>
  );
};

export default AdminPersonalInformation;

import React, { useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@mui/material";
import FaqListDeleteIcon from "../svgIcons/FaqListDeleteIcon";
import { MAX_ATTACHMENTS_SIZE, MAX_ATTACHMENTS_SIZE_BYTE } from "../constants";
import AttachmentsList from "./AttachmentsList";
import DragAndDrop from "./DragAndDrop";
import ComponentSwitcher from "../common/ComponentSwitcher";
import {
  isAcceptedFileType,
  isOverallSizeExceedingMaxSize,
} from "../utils/validate";
import filterNonExistingFilesByLastModified from "../utils/filterNonExistingFilesByLastModified";
import { useSnackbar } from "notistack";
import BrowseFiles from "./BrowseFiles";

export const PREFIX = "Attachments";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  subTitle: `${PREFIX}-subTitle`,
  caption: `${PREFIX}-caption`,
  deleteBtn: `${PREFIX}-deleteBtn`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
  },
  [`& .${classes.caption}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    fontSize: "14px",
    lineHeight: "19px",
    color: theme.palette.viduxColors.grey_5,
    fontWeight: 400,
    "& div:nth-of-type(3)": {
      fontWeight: 800,
    },
  },
  [`& .${classes.deleteBtn}`]: {
    width: "100%",
    padding: "10px",
    backgroundColor: theme.palette.viduxColors.grey_0,
    color: theme.palette.viduxColors.grey_6,
    borderRadius: "4px",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "19px",
    gap: "5px",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-start",
      justifyContent: "flex-end",
    },
  },
}));

const Attachments = ({ attachments, setFormData }) => {
  const { t } = useTranslation();
  const [fileInputKey, setFileInputKey] = useState(1);
  const { enqueueSnackbar } = useSnackbar();

  const onChangeAddFiles = useCallback(
    (event) => {
      const fileList = [...event.target.files].filter((file) =>
        isAcceptedFileType(file),
      );
      setFormData((prev) => {
        let filteredList = filterNonExistingFilesByLastModified(fileList, prev);

        if (isOverallSizeExceedingMaxSize(prev, filteredList)) {
          enqueueSnackbar(
            t("contactUs.attachmentsSizeError", { size: MAX_ATTACHMENTS_SIZE }),
            {
              variant: "error",
            },
          );
          return prev;
        }

        return {
          ...prev,
          attachments: [...prev.attachments].concat(filteredList),
        };
      });
      setFileInputKey((prev) => prev + 1);
    },
    [setFormData, enqueueSnackbar, t],
  );

  const onClickDeleteAll = useCallback(() => {
    setFormData((prev) => ({ ...prev, attachments: [] }));
  }, [setFormData]);

  const onClickDeleteItem = useCallback(
    (idx) => {
      setFormData((prev) => {
        let prevAttachments = [...prev.attachments];
        prevAttachments.splice(idx, 1);
        return { ...prev, attachments: prevAttachments };
      });
    },
    [setFormData],
  );

  const getAttachmentSize = useCallback(
    () =>
      attachments.reduce((acc, current) => {
        return acc + current.size;
      }, 0),
    [attachments],
  );

  const calculateSizeLeft = useCallback(() => {
    const currentSize = getAttachmentSize();
    const diff = MAX_ATTACHMENTS_SIZE_BYTE - currentSize;
    const MB = 1048576;
    if (diff < MB) {
      return (diff / 1024).toFixed(0) + " KB";
    }
    return (diff / MB).toFixed(0) + " MB";
  }, [getAttachmentSize]);

  return (
    <Root className={classes.root}>
      <div className={classes.title}> {t("contactUs.attachmentsTitle")} </div>
      <div className={classes.subTitle}>
        {t("contactUs.attachmentsSubTitle")}
      </div>
      <ComponentSwitcher
        mobileViewPortComponent={
          <BrowseFiles
            fileInputKey={fileInputKey}
            onChangeAddFiles={onChangeAddFiles}
          />
        }
        pcViewPortComponent={
          <DragAndDrop
            fileInputKey={fileInputKey}
            setFormData={setFormData}
            onChangeAddFiles={onChangeAddFiles}
          />
        }
        atBreakPoint="sm"
      />
      <div className={classes.caption}>
        <div> {t("contactUs.attachmentsAcceptables")}</div>
        <div>
          {t("contactUs.attachmentsMaxSize", { maxSize: MAX_ATTACHMENTS_SIZE })}
        </div>
        {attachments.length > 0 && (
          <div>
            {t("contactUs.attachmentsSizeLeft", { size: calculateSizeLeft() })}
          </div>
        )}
      </div>
      <AttachmentsList
        attachments={attachments}
        onClickDeleteItem={onClickDeleteItem}
      />
      <ButtonBase className={classes.deleteBtn} onClick={onClickDeleteAll}>
        <FaqListDeleteIcon />
        {t("contactUs.deleteAllButtonText")}
      </ButtonBase>
    </Root>
  );
};

export default Attachments;

import React from "react";

const SendIcon = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18.5L20 10L0 1.5V8.11111L14.2857 10L0 11.8889V18.5Z"
      fill="white"
    />
  </svg>
);

export default SendIcon;

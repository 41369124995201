import React from "react";
import { styled } from "@mui/material/styles";
import { Typography, Box, Backdrop, CircularProgress } from "@mui/material";
const PREFIX = "SendingSpinner";

const classes = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
  progress: `${PREFIX}-progress`,
};

const Root = styled(Backdrop)(({ theme }) => ({
  [`&.${classes.root}`]: {
    zIndex: theme.zIndex.drawer + 10000,
    color: "white",
  },
  [`& .${classes.text}`]: {
    color: "white",
    fontSize: "18px",
    fontWeight: "800",
  },
  [`& .${classes.progress}`]: {
    width: "120px !important",
    height: "120px !important",
  },
}));

const SendingSpinner = ({ sendingPercent }) => {
  const displayedPercent = Number(sendingPercent);
  const show = displayedPercent > 0 && displayedPercent < 100;
  return (
    <>
      {show && (
        <Root className={classes.root} open={true}>
          <Box position="relative" display="inline-flex">
            <CircularProgress
              variant="determinate"
              value={displayedPercent}
              className={classes.progress}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="caption"
                component="div"
                color="primary"
                className={classes.text}
              >
                {`${displayedPercent}%`}
              </Typography>
            </Box>
          </Box>
        </Root>
      )}
    </>
  );
};

export default SendingSpinner;

import React, { useCallback, useMemo, useReducer } from "react";
import SendingSpinnerContext from "./sendingSpinnerContext";
import SendingSpinnerReducer from "./sendingSpinnerReducer";
import { SET_PERCENT } from "../types";
import PropTypes from "prop-types";

const SendingSpinnerState = ({ children }) => {
  const initialState = {
    percent: 0,
  };

  const [state, dispatch] = useReducer(SendingSpinnerReducer, initialState);

  const setPercent = useCallback((percent) => {
    dispatch({ type: SET_PERCENT, payload: percent });
  }, []);

  const percentData = useMemo(
    () => ({
      percent: state.percent,
      setPercent,
    }),
    [state.percent, setPercent],
  );

  return (
    <SendingSpinnerContext.Provider value={percentData}>
      {children}
    </SendingSpinnerContext.Provider>
  );
};

SendingSpinnerState.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SendingSpinnerState;

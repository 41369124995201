import React from "react";

const AirplaneIcon = ({ className }) => (
  <svg
    className={className}
    width="62"
    height="55"
    viewBox="0 0 62 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1546_18547)">
      <g clipPath="url(#clip1_1546_18547)">
        <path
          d="M48.1207 32.621C47.8168 32.621 47.6142 32.5197 47.4116 32.3171L39.5096 24.4151C39.1044 24.0099 39.1044 23.5034 39.4083 23.0982L48.6273 13.3727L37.8887 21.5785C37.6861 21.6798 37.4835 21.7812 37.2809 21.7812C36.9769 21.7812 36.7743 21.6799 36.5717 21.4772L29.6828 14.5883C29.4802 14.3857 29.3789 13.9805 29.3789 13.6766C29.3789 13.3727 29.6828 13.0687 29.9867 12.9674L59.6698 1.11449C59.7711 1.01318 59.8724 1.01318 59.9737 1.01318C60.2776 1.01318 60.4802 1.11449 60.6828 1.31711C60.9868 1.62103 61.0881 2.02626 60.8854 2.43148L49.1338 32.0132C49.0325 32.3171 48.7286 32.5197 48.4247 32.621C48.3233 32.621 48.222 32.621 48.1207 32.621Z"
          fill="#DAF7DD"
        />
        <path
          d="M59.9735 2.02614L48.1206 31.6078L39.8134 23.3007L52.4768 9.72549C52.6794 9.52288 52.3755 9.32026 52.1728 9.42157L37.2807 20.768L30.3918 13.8791L59.9735 2.02614ZM59.9735 0C59.7709 0 59.467 0 59.2644 0.101307L29.5813 11.9542C28.9735 12.1569 28.467 12.7647 28.3657 13.4739C28.2644 14.183 28.467 14.7908 28.8722 15.2974L37.2807 23.7059L37.4833 29.3791C37.4833 30.2908 38.5977 30.6961 39.2055 30.0882L41.4343 27.8595L46.601 33.0261C47.0062 33.4314 47.5127 33.634 48.0193 33.634C48.1206 33.634 48.3232 33.634 48.4245 33.634C49.1336 33.5327 49.6402 33.0261 49.9441 32.4183L61.8983 2.73529C62.2023 2.02614 61.9996 1.11438 61.4931 0.506536C61.0879 0.202614 60.5813 0 59.9735 0Z"
          fill="#38AE4C"
        />
        <path
          d="M0.506536 55.0098C0.202614 55.0098 0 54.8072 0 54.5033C0 54.1994 0.202614 53.9968 0.506536 53.9968C15.5 53.6928 29.5817 47.7157 40.0163 36.9772C40.219 36.7745 40.5229 36.7745 40.7255 36.9772C40.9281 37.1798 40.9281 37.4837 40.7255 37.6863C30.0882 48.5262 15.8039 54.7059 0.506536 55.0098Z"
          fill="#38AE4C"
        />
        <path
          d="M0.506536 40.8269C0.202614 40.8269 0 40.6242 0 40.3203C0 40.0164 0.202614 39.8138 0.506536 39.8138C12.1569 39.6112 23.1993 35.7615 32.4183 28.5687C32.6209 28.3661 32.9248 28.4674 33.1275 28.67C33.3301 28.8726 33.2288 29.1765 33.0261 29.3792C23.7059 36.6733 12.4608 40.6242 0.506536 40.8269Z"
          fill="#38AE4C"
        />
        <path
          d="M0.506536 24.6176C0.202614 24.6176 0 24.415 0 24.1111C0 23.8072 0.202614 23.6046 0.506536 23.6046C8.91503 23.5033 17.1209 21.6797 24.719 18.134C24.9216 18.0327 25.2255 18.134 25.4281 18.3366C25.5294 18.6405 25.4281 18.8431 25.2255 19.0457C17.4248 22.5915 9.11765 24.415 0.506536 24.6176Z"
          fill="#38AE4C"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1546_18547">
        <rect width="62" height="55" fill="white" />
      </clipPath>
      <clipPath id="clip1_1546_18547">
        <rect width="62" height="55.0098" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AirplaneIcon;

import React from "react";

const Account = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      className="svgPath"
      d="M3.33333 15.5556C3.33333 13.3333 7.77778 12.1111 10 12.1111C12.2222 12.1111 16.6667 13.3333 16.6667 15.5556V16.6667H3.33333V15.5556ZM13.3333 6.66667C13.3333 8.55556 11.8889 10 10 10C8.11111 10 6.66667 8.55556 6.66667 6.66667C6.66667 4.77778 8.11111 3.33333 10 3.33333C11.8889 3.33333 13.3333 4.77778 13.3333 6.66667ZM0 2.22222V17.7778C0 19 1 20 2.22222 20H17.7778C19 20 20 19 20 17.7778V2.22222C20 1 19 0 17.7778 0H2.22222C1 0 0 1 0 2.22222Z"
      fill="#565656"
    />
  </svg>
);

export default Account;

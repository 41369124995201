import React from "react";
import Collapse from "@mui/material/Collapse";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import AdminCollapsingRowContent from "../AdminCollapsingRowContent";

const PREFIX = "AdminTableCollapsingRow";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled(TableRow)(({ _theme }) => ({
  [`&.${classes.root}`]: {
    "& .MuiTableCell-root": {
      padding: 0,
    },
  },
}));

const AdminTableCollapsingRow = ({ row, open }) => {
  return (
    <Root className={classes.root}>
      <TableCell colSpan={5}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <AdminCollapsingRowContent row={row} />
        </Collapse>
      </TableCell>
    </Root>
  );
};

export default AdminTableCollapsingRow;

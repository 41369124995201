import React from "react";

const ContactUsMenuIcon = ({ className }) => (
  <svg
    className={className}
    width="53"
    height="62"
    viewBox="0 0 53 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1304_15439)">
      <g clipPath="url(#clip1_1304_15439)">
        <path
          d="M37.7 62H3.3C1.5 62 0 60.5 0 58.7V14.3C0 12.5 1.5 11 3.3 11H7C7.6 11 8 11.4 8 12C8 12.6 7.6 13 7 13H3.3C2.6 13 2 13.6 2 14.3V58.7C2 59.4 2.6 60 3.3 60H37.7C38.4 60 39 59.4 39 58.7V39C39 38.4 39.4 38 40 38C40.6 38 41 38.4 41 39V58.7C41 60.5 39.5 62 37.7 62Z"
          fill="#38AE4C"
        />
        <path
          d="M41 23C40.4 23 40 22.6 40 22V14.3C40 13.6 39.4 13 38.7 13H36C35.4 13 35 12.6 35 12C35 11.4 35.4 11 36 11H38.7C40.5 11 42 12.5 42 14.3V22C42 22.6 41.6 23 41 23Z"
          fill="#38AE4C"
        />
        <path d="M37 15H4V58H37V15Z" fill="#DAF7DD" />
        <path
          d="M11.8004 30.6C11.6004 30.6 11.4004 30.5 11.2004 30.3L8.00039 27.2C7.70039 26.9 7.70039 26.3 8.00039 26C8.30039 25.7 8.90039 25.7 9.20039 26L11.8004 28.6L16.6004 23.8C16.9004 23.5 17.5004 23.5 17.8004 23.8C18.1004 24.1 18.1004 24.7 17.8004 25L12.4004 30.4C12.2004 30.5 12.0004 30.6 11.8004 30.6Z"
          fill="#38AE4C"
        />
        <path
          d="M11.8004 41.7C11.6004 41.7 11.4004 41.6 11.2004 41.4L8.00039 38.3C7.70039 38 7.70039 37.4 8.00039 37.1C8.30039 36.8 8.90039 36.8 9.20039 37.1L11.8004 39.7L16.6004 34.9C16.9004 34.6 17.5004 34.6 17.8004 34.9C18.1004 35.2 18.1004 35.8 17.8004 36.1L12.4004 41.5C12.2004 41.6 12.0004 41.7 11.8004 41.7Z"
          fill="#38AE4C"
        />
        <path
          d="M11.8004 52.8C11.6004 52.8 11.3004 52.7 11.2004 52.5L8.00039 49.4C7.70039 49.1 7.70039 48.5 8.00039 48.2C8.30039 47.9 8.90039 47.9 9.20039 48.2L11.8004 50.8L16.6004 46C16.9004 45.7 17.5004 45.7 17.8004 46C18.1004 46.3 18.1004 46.9 17.8004 47.2L12.4004 52.6C12.2004 52.7 12.0004 52.8 11.8004 52.8Z"
          fill="#38AE4C"
        />
        <path
          d="M34 50H22C21.4 50 21 49.6 21 49C21 48.4 21.4 48 22 48H34C34.6 48 35 48.4 35 49C35 49.6 34.6 50 34 50Z"
          fill="#38AE4C"
        />
        <path
          d="M24 39H22C21.4 39 21 38.6 21 38C21 37.4 21.4 37 22 37H24C24.6 37 25 37.4 25 38C25 38.6 24.6 39 24 39Z"
          fill="#38AE4C"
        />
        <path
          d="M34 28H22C21.4 28 21 27.6 21 27C21 26.4 21.4 26 22 26H34C34.6 26 35 26.4 35 27C35 27.6 34.6 28 34 28Z"
          fill="#38AE4C"
        />
        <path
          d="M48.3969 18.5415L46.0635 20.8749L49.9525 24.764L52.286 22.4306L48.3969 18.5415Z"
          fill="white"
        />
        <path
          d="M43.8 22.4L47.6 26.3L30.6 44L26 44.8V40.9L43.8 22.4Z"
          fill="white"
        />
        <path
          d="M25.3998 46.1C25.1998 46.1 24.9998 46 24.7998 45.8C24.5998 45.6 24.4998 45.3 24.5998 45L25.5998 40C25.5998 39.8 25.6998 39.7 25.7998 39.6L43.2998 22.1C43.5998 21.8 44.1998 21.8 44.4998 22.1L48.4998 26.1C48.7998 26.4 48.7998 27 48.4998 27.3L30.9998 44.8C30.8998 44.9 30.6998 45 30.5998 45L25.5998 46C25.4998 46 25.3998 46.1 25.3998 46.1ZM27.0998 40.6L26.3998 44.1L29.8998 43.4L46.5998 26.7L43.7998 23.9L27.0998 40.6Z"
          fill="#38AE4C"
        />
        <path
          d="M49.9 25.6C49.7 25.6 49.5 25.5 49.3 25.3L45.3 21.3C45.1 21.1 45 20.9 45 20.7C45 20.5 45.1 20.2 45.3 20.1L47.7 17.7C48 17.4 48.6 17.4 48.9 17.7L52.9 21.7C53.1 21.9 53.2 22.1 53.2 22.3C53.2 22.5 53.1 22.8 52.9 22.9L50.5 25.3C50.3 25.5 50.1 25.6 49.9 25.6ZM47.1 20.7L49.9 23.5L51 22.4L48.2 19.6L47.1 20.7Z"
          fill="#38AE4C"
        />
        <path
          d="M38.5994 32.8C38.3994 32.8 38.1994 32.7 37.9994 32.5C37.6994 32.2 37.6994 31.6 37.9994 31.3L45.1994 24.1C45.4994 23.8 46.0994 23.8 46.3994 24.1C46.6994 24.4 46.6994 25 46.3994 25.3L39.1994 32.5C39.0994 32.7 38.8994 32.8 38.5994 32.8Z"
          fill="#38AE4C"
        />
        <path
          d="M30.4002 45.1C30.2002 45.1 30.0002 45 29.8002 44.8L25.8002 40.8C25.5002 40.5 25.5002 39.9 25.8002 39.6C26.1002 39.3 26.7002 39.3 27.0002 39.6L31.0002 43.6C31.3002 43.9 31.3002 44.5 31.0002 44.8C30.8002 45 30.6002 45.1 30.4002 45.1Z"
          fill="#38AE4C"
        />
        <path d="M32.9998 7.70001H9.7998V16.2H32.9998V7.70001Z" fill="white" />
        <path
          d="M21 10.8C23.7614 10.8 26 8.56141 26 5.79999C26 3.03856 23.7614 0.799988 21 0.799988C18.2386 0.799988 16 3.03856 16 5.79999C16 8.56141 18.2386 10.8 21 10.8Z"
          fill="white"
        />
        <path
          d="M21.4 2C23.5 2 25.2 3.7 25.2 5.8C25.2 6.1 25.2 6.3 25.1 6.6L24.6 9H27H31.4C31.6 9 31.7 9.1 31.7 9.3V14.7C31.7 14.9 31.6 15 31.4 15H11.3C11.1 15 11 14.9 11 14.7V9.3C11 9.1 11.1 9 11.3 9H15.7H18.1L17.6 6.6C17.5 6.4 17.5 6.1 17.5 5.8C17.6 3.7 19.3 2 21.4 2ZM21.4 0C18.2 0 15.6 2.6 15.6 5.8C15.6 6.2 15.6 6.6 15.7 7H11.3C10.1 6.9 9 8 9 9.3V14.7C9 16 10 17 11.3 17H31.4C32.7 17 33.7 16 33.7 14.7V9.3C33.7 8 32.7 7 31.4 7H27C27.1 6.6 27.1 6.2 27.1 5.8C27.2 2.6 24.6 0 21.4 0Z"
          fill="#38AE4C"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1304_15439">
        <rect width="53" height="62" fill="white" />
      </clipPath>
      <clipPath id="clip1_1304_15439">
        <rect width="53.1" height="62" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ContactUsMenuIcon;

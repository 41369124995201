import PropTypes from "prop-types";
import React from "react";

const AdminMultiLineTextMapper = ({ value }) =>
  value
    .toString()
    .split("\n")
    .map((item, index) => <div key={index}>{item}</div>);

AdminMultiLineTextMapper.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
};

export default AdminMultiLineTextMapper;

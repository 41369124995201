import React, { useCallback, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import clsx from "clsx";

export const PREFIX = "PersonalInformation";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  input: `${PREFIX}-input`,
  error: `${PREFIX}-error`,
};

const Root = styled("div")(({ _theme }) => ({
  [`&.${classes.root}`]: {
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    gap: "10px",
  },
  [`& .${classes.input}`]: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    flexDirection: "column",
  },
}));

const PersonalInformation = ({ formData, setFormData, validation }) => {
  const { t } = useTranslation();
  const nameInit = useRef(true);
  const emailInit = useRef(true);
  const phoneInit = useRef(true);

  const onChangeName = useCallback(
    (event) => {
      setFormData((prev) => ({ ...prev, name: event.target.value }));
      nameInit.current = false;
    },
    [setFormData],
  );

  const onChangeEmailAddress = useCallback(
    (event) => {
      setFormData((prev) => ({ ...prev, emailAddress: event.target.value }));
      emailInit.current = false;
    },
    [setFormData],
  );

  const onChangePhone = useCallback(
    (event) => {
      setFormData((prev) => ({ ...prev, phone: event.target.value }));
      phoneInit.current = false;
    },
    [setFormData],
  );

  return (
    <Root className={classes.root}>
      <div className={classes.title}>
        {t("contactUs.personalInformationTitle")}
      </div>
      <div className={classes.input}>
        <TextField
          value={formData.name}
          aria-label="name"
          label={t("contactUs.name")}
          onChange={onChangeName}
          fullWidth
          className={clsx({
            [classes.error]: !validation.isValidName && !nameInit.current,
          })}
        />
        <TextField
          value={formData.emailAddress}
          aria-label="email address"
          label={t("contactUs.emailAddress")}
          onChange={onChangeEmailAddress}
          fullWidth
          className={clsx({
            [classes.error]: !validation.isValidEmail && !emailInit.current,
          })}
        />
        <TextField
          value={formData.phone}
          aria-label="phone"
          label={t("contactUs.phone")}
          onChange={onChangePhone}
          fullWidth
          className={clsx({
            [classes.error]: !validation.isValidPhone && !phoneInit.current,
          })}
        />
      </div>
    </Root>
  );
};

export default PersonalInformation;

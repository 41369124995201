import { TableHead, TableRow, TableSortLabel } from "@mui/material";
import PropTypes from "prop-types";
import AdminStyledTableCell from "./AdminStyledTableCell";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ASC, DESC } from "../../constants/index";

const AdminSortableTableHead = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
  className,
}) => {
  const getNewOrder = (currnetOrderBy, newOrderBy, currentOrder) => {
    if (currnetOrderBy !== newOrderBy) return ASC;
    else if (currentOrder === ASC) return DESC;
    else return ASC;
  };
  return (
    <TableHead data-testid="asth" className={className}>
      <TableRow>
        {headCells.map((headCell, idx) => (
          <AdminStyledTableCell
            key={headCell.id}
            isFirstCell={idx === 0}
            isLastCell={idx === headCells.length - 1}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={getNewOrder(orderBy, headCell.id, order)}
              onClick={() =>
                onRequestSort(
                  headCell.id,
                  getNewOrder(orderBy, headCell.id, order),
                )
              }
              IconComponent={ArrowDropDownIcon}
            >
              {headCell.label}
            </TableSortLabel>
          </AdminStyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

AdminSortableTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf([ASC, DESC]).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array,
  className: PropTypes.string,
};

export default AdminSortableTableHead;

import {
  COUNTRY_OPTIONS,
  FORM_DESCRIPTION_MIN_CHARACTERS,
  ACCEPTED_FILE_TYPES,
  MAX_ATTACHMENTS_SIZE_BYTE,
} from "../constants";

export const isAcceptedFileType = (file) => {
  const fileType = file.type.split("/")[0];
  return ACCEPTED_FILE_TYPES.includes(fileType);
};

export const checkName = (name) => Boolean(name && name.length > 0);

export const checkEmail = (email) => {
  const emailFormat =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return Boolean(email && email.match(emailFormat));
};

export const checkCountry = (country) => {
  return (
    COUNTRY_OPTIONS.findIndex((countryObj) => countryObj.VALUE === country) !==
    -1
  );
};

export const checkSerialNumber = (serialNumber) => {
  const serialNumberFormat = /^[0-9a-zA-Z-]{4,16}$/;
  return Boolean(serialNumber && serialNumber.match(serialNumberFormat));
};

export const checkPhone = (phone) => {
  const phoneFormat = /^([+][-\\\s/0-9])?[-\\\s/0-9]*$/;
  return Boolean(phone && phone.match(phoneFormat));
};

export const checkDescription = (description) =>
  Boolean(description && FORM_DESCRIPTION_MIN_CHARACTERS <= description.length);

export const checkForm = (formData) =>
  checkName(formData.name) &&
  checkEmail(formData.emailAddress) &&
  checkCountry(formData.country) &&
  checkSerialNumber(formData.serialNumber) &&
  checkPhone(formData.phone) &&
  checkDescription(formData.description);

export const isOverallSizeExceedingMaxSize = (prevFileList, filteredList) => {
  const currentSize = prevFileList.attachments.reduce((acc, current) => {
    return acc + current.size;
  }, 0);
  const latestAddedSize = filteredList.reduce((acc, current) => {
    return acc + current.size;
  }, 0);
  return currentSize + latestAddedSize > MAX_ATTACHMENTS_SIZE_BYTE;
};

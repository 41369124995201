import React, { useCallback, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import clsx from "clsx";
import { FORM_DESCRIPTION_MIN_CHARACTERS } from "../constants/index";

export const PREFIX = "Description";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  subTitle: `${PREFIX}-subTitle`,
  charsAtLeast: `${PREFIX}-charsAtLeast`,
  counter: `${PREFIX}-counter`,
  counterError: `${PREFIX}-counterError`,
  error: `${PREFIX}-error`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
    "& .MuiInputBase-input": {
      height: "167px !important",
      overflowY: "auto !important",
    },
  },
  [`& .${classes.charsAtLeast}`]: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "14px",
    lineHeight: "19px",
    [`& .${classes.counter}`]: {
      color: theme.palette.viduxColors.green_3,
      fontWeight: 800,
    },
    [`& .${classes.counterError}`]: {
      color: theme.palette.viduxColors.red_2,
    },
    "& div:nth-of-type(2)": {
      color: theme.palette.viduxColors.grey_5,
      fontWeight: 400,
    },
  },
}));

const Description = ({ setFormData, validation }) => {
  const { t } = useTranslation();
  const [charCounter, setCharCounter] = useState(0);
  const init = useRef(true);

  const onChangeDescription = useCallback(
    (event) => {
      setFormData((prev) => ({ ...prev, description: event.target.value }));
      setCharCounter(event.target.value.length);
      init.current = false;
    },
    [setFormData],
  );

  return (
    <Root className={classes.root}>
      <div className={classes.title}>{t("contactUs.descriptionTitle")}</div>
      <div className={classes.subTitle}>
        {t("contactUs.descriptionSubTitle")}
      </div>
      <TextField
        defaultValue=""
        inputProps={{ "aria-label": "description" }}
        label={t("contactUs.description")}
        onChange={onChangeDescription}
        multiline
        fullWidth
        className={clsx({
          [classes.error]: !validation.isValidDescription && !init.current,
        })}
      />
      <div className={classes.charsAtLeast}>
        <div
          className={clsx(classes.counter, {
            [classes.counterError]: !validation.isValidDescription,
          })}
        >
          {charCounter}
        </div>
        <div>{`/${FORM_DESCRIPTION_MIN_CHARACTERS} characters at least`}</div>
      </div>
    </Root>
  );
};

export default Description;
